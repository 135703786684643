export default function Interface() {
  return (
    <section className="pb-6 lg:pb-24 pt-6 lg:pt-12 px-6 lg:px-12 bg-gray-50 grid grid-cols-1 lg:grid-cols-2 gap-6">
      <div className="lg:translate-y-12 flex flex-col gap-4">
        <h2 className="color-main text-2xl lg:text-4xl">
          Tworzenie nowej oferty
        </h2>
        <img
          className="rounded-xl shadow-xl border border-[#f0f0f0]"
          src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/lets-offer/ui-form.png"
          alt=""
        />
      </div>
      <div className="lg:-translate-y-12 flex flex-col gap-4">
        <h2 className="color-main text-2xl lg:text-4xl">Widok Twoich ofert</h2>
        <img
          className="-rounded-xl shadow-xl border border-[#f0f0f0]"
          src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/lets-offer/ui-table.png"
          alt=""
        />
      </div>
    </section>
  );
}
