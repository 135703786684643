import { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { IoIosSettings, IoMdNotifications } from "react-icons/io";
import { IoLogInOutline } from "react-icons/io5";
import { FaListUl } from "react-icons/fa6";
import axios from "axios";
import Search from "../common/search";
import NotificationPanel from "../notification/panel";
import { useAuth } from "../../context/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import API_BASE_URL from "../../config";

export default function Header() {
  const [notifyPanel, setNotifyPanel] = useState(false);
  const [hasNotifications, setHasNotifications] = useState(false);
  const { logout, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated) {
      const token = localStorage.getItem("authToken");
      if (token) {
        axios
          .get(`${API_BASE_URL}/api/notifications/`, {
            headers: {
              Authorization: `Token ${token}`,
            },
          })
          .then((response) => {
            setHasNotifications(response.data.length > 0);
          })
          .catch((error) => console.error(error));
      } else {
        console.error("No auth token found");
      }
    }
  }, [isAuthenticated]);

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const handleNotificationChange = (hasNotifications: boolean) => {
    setHasNotifications(hasNotifications);
  };

  return (
    <>
      <nav className="flex flex-col-reverse gap-6 lg:gap-0 lg:flex-row items-center justify-between w-full p-6 lg:p-12">
        <div className="flex flex-col lg:flex-row items-center justify-start gap-6 w-full max-w-[600px]">
          {location.pathname === "/panel/nowa-oferta" ||
          location.pathname === "/panel/ustawienia" ? (
            <a className="w-full lg:w-auto shrink-0" href="/panel">
              <button className="button-main shrink-0 w-full lg:w-auto">
                <FaListUl />
                <span>Wszystkie oferty</span>
              </button>
            </a>
          ) : (
            <a className="w-full lg:w-auto shrink-0" href="/panel/nowa-oferta">
              <button className="button-main shrink-0 w-full lg:w-auto animate-pulse">
                <FaPlus />
                <span>Utwórz ofertę</span>
              </button>
            </a>
          )}
          <Search />
        </div>
        <div className="flex flex-row items-center justify-end gap-6">
          <IoMdNotifications
            onClick={() => setNotifyPanel(true)}
            className={`text-4xl color-main cursor-pointer hover:opacity-50 transition duration-300 ease-linear ${
              hasNotifications ? "animate-pulse" : ""
            }`}
          />
          <IoIosSettings
            onClick={() => navigate("/panel/ustawienia")}
            className="text-4xl color-main cursor-pointer hover:opacity-50 transition duration-300 ease-linear"
          />
          <IoLogInOutline
            onClick={handleLogout}
            className="text-4xl color-main cursor-pointer hover:opacity-50 transition duration-300 ease-linear"
          />
        </div>
      </nav>
      {notifyPanel && (
        <NotificationPanel
          closePanel={() => setNotifyPanel(false)}
          onNotificationChange={handleNotificationChange}
        />
      )}
    </>
  );
}
