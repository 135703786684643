import Hero from "../compontents/home/hero";
import WhatIsIt from "../compontents/home/whatIsIt";
import HowItWorks from "../compontents/home/howItWorks";
import Interface from "../compontents/home/interface";
import CaseStudy from "../compontents/home/caseStudy";
import Cta from "../compontents/home/cta";
import Functionalities from "../compontents/home/functionalities";
import Pricing from "../compontents/home/pricing";
import Footer from "../compontents/common/footer";
import Problems from "../compontents/home/problems";

import HeaderLeft from "../compontents/common/headerLeft";
import HeaderSlide from "../compontents/common/headerSlide";
import HeaderTop from "../compontents/common/headerTop";

import { useState } from "react";
import ScrollToElement from "../compontents/common/scroll";
import Faq from "../compontents/home/faq";
import Process from "../compontents/home/process";

export default function Home() {
  const [showSlide, setShowSlide] = useState(false);

  return (
    <>
      {showSlide && <HeaderSlide onClick={() => setShowSlide(false)} />}
      <HeaderTop />
      <div className="flex flex-row items-start justify-start h-screen">
        <HeaderLeft onClick={() => setShowSlide(true)} />
        <div className="w-full h-screen overflow-y-auto">
          <ScrollToElement />
          <Hero />
          <WhatIsIt />
          <HowItWorks />
          <Process />
          <Interface />
          <Problems />
          <Cta />
          <Functionalities />
          <Pricing />
          <CaseStudy />
          <Faq />
          <Footer />
        </div>
      </div>
    </>
  );
}
