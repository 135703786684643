import { useState, useEffect } from "react";
import {
  FaBuilding,
  FaFileInvoice,
  FaMapMarkedAlt,
  FaCity,
} from "react-icons/fa";
import { GoNumber } from "react-icons/go";
import { GiPostOffice } from "react-icons/gi";
import API_BASE_URL from "../../config";

export default function InvoiceFormSettings() {
  const [companyName, setCompanyName] = useState("");
  const [taxId, setTaxId] = useState("");
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  useEffect(() => {
    fetchProfileData();
  }, []);

  const fetchProfileData = async () => {
    const token = localStorage.getItem("authToken");

    if (!token) {
      setError("No token found. Please log in.");
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/api/auth/profile/`, {
        method: "GET",
        headers: {
          Authorization: `Token ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setCompanyName(data.company_name || "");
        setTaxId(data.company_nip || "");
        setAddress(data.company_address || "");
        setPostalCode(data.company_postcode || "");
        setCity(data.company_city || "");
        setError(null);
      } else {
        const result = await response.json();
        setError(result.errors || "Failed to fetch profile data.");
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
      setError("An error occurred while fetching profile data.");
    }
  };

  const handleUpdate = async () => {
    const token = localStorage.getItem("authToken");

    if (!token) {
      setError("No token found. Please log in.");
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/api/auth/profile/update/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify({
          company_name: companyName,
          company_nip: taxId,
          company_address: address,
          company_postcode: postalCode,
          company_city: city,
        }),
      });

      if (response.ok) {
        const result = await response.json();
        setSuccess("Profile updated successfully");
        setError(null);
      } else {
        const result = await response.json();
        if (typeof result.errors === "object") {
          setError(Object.values(result.errors).join(", "));
        } else {
          setError(result.errors);
        }
        setSuccess(null);
      }
    } catch (error) {
      console.error("Error occurred:", error);
      setError("An error occurred. Please try again.");
      setSuccess(null);
    }
  };

  return (
    <div className="flex flex-col p-6 lg:p-12 gap-2 shadow-main items-start justify-start">
      <div className="flex flex-col lg:flex-row items-start lg:items-center justify-start gap-2">
        <FaFileInvoice className="text-2xl color-main" />
        <h2 className="text-4xl color-main">Dane do faktury</h2>
      </div>{" "}
      <p className="text-base color-add opacity-80 mb-2">
        Jeżeli chcesz otrzymać fakturę VAT wprowadź poprawne dane firmy.
      </p>
      <div className="flex flex-col gap-2 lg:gap-6 w-full items-start justify-start">
        <div className="relative w-full p-2">
          <input
            type="text"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            className="pl-8 pr-2 py-2 outline-none w-full border-b border-gray-400 focus:border-[#ea5b0c] focus:placeholder:opacity-50 placeholder:transition placeholder:duration-300 placeholder:ease-linear transition duration-300 ease-linear"
            placeholder="Nazwa firmy"
          />
          <FaBuilding className="absolute left-2 top-1/2 transform -translate-y-1/2 text-xl color-main pointer-events-none" />
        </div>
        <div className="relative w-full p-2">
          <input
            type="text"
            value={taxId}
            onChange={(e) => setTaxId(e.target.value)}
            className="pl-8 pr-2 py-2 outline-none w-full border-b border-gray-400 focus:border-[#ea5b0c] focus:placeholder:opacity-50 placeholder:transition placeholder:duration-300 placeholder:ease-linear transition duration-300 ease-linear"
            placeholder="NIP"
          />
          <GoNumber className="absolute left-2 top-1/2 transform -translate-y-1/2 text-xl color-main pointer-events-none" />
        </div>
        <div className="relative w-full p-2">
          <input
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            className="pl-8 pr-2 py-2 outline-none w-full border-b border-gray-400 focus:border-[#ea5b0c] focus:placeholder:opacity-50 placeholder:transition placeholder:duration-300 placeholder:ease-linear transition duration-300 ease-linear"
            placeholder="Adres (ulica, numer domu/mieszkania)"
          />
          <FaMapMarkedAlt className="absolute left-2 top-1/2 transform -translate-y-1/2 text-xl color-main pointer-events-none" />
        </div>
        <div className="flex flex-col lg:flex-row items-start justify-start gap-0 lg:gap-6 w-full">
          <div className="relative w-full p-2">
            <input
              type="text"
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
              className="pl-8 pr-2 py-2 outline-none w-full border-b border-gray-400 focus:border-[#ea5b0c] focus:placeholder:opacity-50 placeholder:transition placeholder:duration-300 placeholder:ease-linear transition duration-300 ease-linear"
              placeholder="Kod pocztowy"
            />
            <GiPostOffice className="absolute left-2 top-1/2 transform -translate-y-1/2 text-xl color-main pointer-events-none" />
          </div>
          <div className="relative w-full p-2">
            <input
              type="text"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              className="pl-8 pr-2 py-2 outline-none w-full border-b border-gray-400 focus:border-[#ea5b0c] focus:placeholder:opacity-50 placeholder:transition placeholder:duration-300 placeholder:ease-linear transition duration-300 ease-linear"
              placeholder="Miejscowość"
            />
            <FaCity className="absolute left-2 top-1/2 transform -translate-y-1/2 text-xl color-main pointer-events-none" />
          </div>
        </div>
        <button onClick={handleUpdate} className="button-main">
          Aktualizuj
        </button>
      </div>{" "}
    </div>
  );
}
