import { useEffect, useRef } from "react";

export default function Hero() {
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (svgRef.current) {
        svgRef.current.classList.add("active");
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (svgRef.current) {
        svgRef.current.classList.add("animate-bounce");
      }
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <section className="lg:pt-28 bg-gray-50">
      <div className="p-6 lg:p-24">
        <h2 className="bold text-4xl lg:text-[12vh] leading-none color-add mb-6 lg:mb-12">
          Bezpłatny dostęp dla Klientów{" "}
          <span className="color-main bold">iFil Group</span>
        </h2>
        <div className="flex flex-row items-center justify-start gap-6">
          <div>
            <p className="text-xl lg:text-3xl color-add opacity-80">
              W ramach każdej realizacji projektu tworzonego przez iFil Group
              otrzymasz bezpłatny dostęp do aplikacji Let's Offer.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
