import { CiCreditCard1 } from "react-icons/ci";
import { FaCode } from "react-icons/fa";
import { MdOutlineSupportAgent } from "react-icons/md";

export default function Ifil() {
  return (
    <div className="flex flex-col p-6 lg:p-12 shadow-main items-start justify-start bg-white gap-4">
      <div className="flex flex-col lg:flex-row items-start lg:items-center justify-start gap-2">
        <FaCode className="text-4xl color-main" />
        <h2 className="text-4xl color-main">Bezpłatny dostęp</h2>
      </div>
      <p className="text-xl color-main">
        Skorzystaj z usług iFil Group i uzyskaj bezpłatny dostęp do Let's Offer!
      </p>
      <a href="https://ifil.pl" className="text-xl">
        <button className="button-main flex flex-row items-center justify-center gap-2">
          <span>www.ifil.pl</span>
        </button>
      </a>
    </div>
  );
}
