import React, { useEffect, useRef, useState } from "react";
import "intersection-observer";
import { FaCheckCircle, FaXing } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";

export default function Pricing() {
  const [monthly, setMonthly] = useState(false);

  return (
    <section
      id="cennik"
      className="bg-main pb-6 lg:pb-0 relative flex flex-col items-center justify-center lg:min-h-[500px]"
    >
      <div className="px-6 lg:px-24 pt-6 lg:pt-12 flex flex-col items-center justify-center">
        <h3 className="text-white text-xl uppercase">Cennik</h3>
        <span className="block w-16 h-[2px] bg-white my-2"></span>
        <h2 className="text-[4vh] lg:text-[8vh] leading-none text-white mb-6 lg:mb-12 text-center">
          Przystępność i proste zasady
        </h2>
        <div className="mb-6 lg:mb-0 grid grid-cols-1 lg:grid-cols-2 gap-2">
          <div className="flex flex-col lg:flex-row items-center justify-center lg:justify-end gap-2">
            <img
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/lets-offer/promo-monthly.png"
              className="promo-badge w-32"
              alt="Oszczędzasz 35%!"
            />
            <button
              onClick={() => setMonthly(false)}
              className={
                monthly
                  ? "rounded-full py-1 w-36 lg:w-48 bg-transparent border border-white text-white hover:bg-gray-100 hover:text-[#ea5b0c] transition duration-300 ease-linear"
                  : "rounded-full py-1 w-36 lg:w-48 bg-white text-[#ea5b0c] px-6"
              }
            >
              Rocznie
            </button>
          </div>
          <div className="flex flex-row items-center justify-start">
            <button
              onClick={() => setMonthly(true)}
              className={
                monthly
                  ? "rounded-full py-1 w-36 lg:w-48 bg-white text-[#ea5b0c] px-6"
                  : "rounded-full py-1 w-36 lg:w-48 bg-transparent border border-white text-white hover:bg-gray-100 hover:text-[#ea5b0c] transition duration-300 ease-linear"
              }
            >
              Miesięcznie
            </button>
          </div>
        </div>
      </div>
      {!monthly && (
        <>
          <div className="px-6 lg:px-24 grid grid-cols-1 lg:grid-cols-3 gap-6 lg:gap-12 w-full lg:translate-y-10">
            <div className="bg-white shadow-xl p-12">
              <p className="color-main font-bold uppercase">Wersja próbna</p>
              <p className="text-5xl my-1 color-add">Bezpłatnie</p>
              <p className="color-add opacity-80 text-sm mb-4">
                Brak opłaty wstępnej
              </p>
              <p className="color-add opacity-80 text-lg mb-6">
                Skorzystaj z 14-dniowego okresu próbnego
              </p>
              <div className="flex flex-col gap-2">
                <div className="flex flex-row items-center justify-start gap-1">
                  <FaCheckCircle color="#ea5b0c" className="text-main" />
                  <p className="color-add">Limit 25 ofert</p>
                </div>
                <div className="flex flex-row items-center justify-start gap-1">
                  <FaCheckCircle color="#ea5b0c" className="text-main" />
                  <p className="color-add">Generowanie ofert</p>
                </div>
                <div className="flex flex-row items-center justify-start gap-1">
                  <FaCheckCircle color="#ea5b0c" className="text-main" />
                  <p className="color-add">Podgląd odczytania</p>
                </div>
                <div className="flex flex-row items-center justify-start gap-1">
                  <FaCheckCircle color="#ea5b0c" className="text-main" />
                  <p className="color-add">Baza ofert w jednym miejscu</p>
                </div>
                <div className="flex flex-row items-center justify-start gap-1 opacity-40">
                  <FaXing color="#ea5b0c" className="text-main" />
                  <p className="color-add">Indywidualny projekt oferty</p>
                </div>
                <div className="flex flex-row items-center justify-start gap-1 opacity-40">
                  <FaXing color="#ea5b0c" className="text-main" />
                  <p className="color-add">Aplikacja na Twojej subdomenie</p>
                </div>
              </div>
            </div>
            <div className="bg-white shadow-xl p-12">
              <div className="flex flex-col-reverse lg:flex-row items-center justify-start gap-2">
                <p className="color-main font-bold uppercase">Podstawowy</p>
                <p className="px-6 py-1 bg-main text-white uppercase text-sm rounded-xl">
                  Polecany
                </p>
              </div>
              <p className="text-5xl my-1 color-add">
                49 zł<span className="text-base"> / miesięcznie</span>
              </p>
              <p className="line-through color-add opacity-80 text-sm mb-4">
                + 399 zł jednorazowa opłata wstępna
              </p>
              <p className="color-add opacity-80 text-lg mb-6">
                Wyróżnij i zorganizuj swoje oferty dla potencjalnych Klientów.
              </p>
              <div className="flex flex-col gap-2">
                <div className="flex flex-row items-center justify-start gap-1">
                  <FaCheckCircle color="#ea5b0c" className="text-main" />
                  <p className="color-add">Brak limitu ofert</p>
                </div>
                <div className="flex flex-row items-center justify-start gap-1">
                  <FaCheckCircle color="#ea5b0c" className="text-main" />
                  <p className="color-add">Generowanie ofert</p>
                </div>
                <div className="flex flex-row items-center justify-start gap-1">
                  <FaCheckCircle color="#ea5b0c" className="text-main" />
                  <p className="color-add">Podgląd odczytania</p>
                </div>
                <div className="flex flex-row items-center justify-start gap-1">
                  <FaCheckCircle color="#ea5b0c" className="text-main" />
                  <p className="color-add">Baza ofert w jednym miejscu</p>
                </div>
                <div className="flex flex-row items-center justify-start gap-1">
                  <FaCheckCircle color="#ea5b0c" className="text-main" />
                  <p className="color-add">Indywidualny projekt oferty</p>
                </div>
                <div className="flex flex-row items-center justify-start gap-1 opacity-40">
                  <FaXing color="#ea5b0c" className="text-main" />
                  <p className="color-add">Aplikacja na Twojej subdomenie</p>
                </div>
              </div>
            </div>
            <div className="bg-white shadow-xl p-12">
              <p className="color-main font-bold uppercase">Spersonalizowany</p>
              <p className="text-5xl my-1 color-add">
                89 zł<span className="text-base"> / miesięcznie</span>
              </p>
              <p className="line-through color-add opacity-80 text-sm mb-4">
                + 399 zł jednorazowa opłata wstępna
              </p>
              <p className="color-add opacity-80 text-lg mb-6">
                Podkreśl swoją markę na własnej subdomenie.
              </p>
              <div className="flex flex-col gap-2">
                <div className="flex flex-row items-center justify-start gap-1">
                  <FaCheckCircle color="#ea5b0c" className="text-main" />
                  <p className="color-add">Brak limitu ofert</p>
                </div>
                <div className="flex flex-row items-center justify-start gap-1">
                  <FaCheckCircle color="#ea5b0c" className="text-main" />
                  <p className="color-add">Generowanie ofert</p>
                </div>
                <div className="flex flex-row items-center justify-start gap-1">
                  <FaCheckCircle color="#ea5b0c" className="text-main" />
                  <p className="color-add">Podgląd odczytania</p>
                </div>
                <div className="flex flex-row items-center justify-start gap-1">
                  <FaCheckCircle color="#ea5b0c" className="text-main" />
                  <p className="color-add">Baza ofert w jednym miejscu</p>
                </div>
                <div className="flex flex-row items-center justify-start gap-1">
                  <FaCheckCircle color="#ea5b0c" className="text-main" />
                  <p className="color-add">Indywidualny projekt oferty</p>
                </div>
                <div className="flex flex-row items-center justify-start gap-1">
                  <FaCheckCircle color="#ea5b0c" className="text-main" />
                  <p className="color-add">Aplikacja na Twojej subdomenie</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {monthly && (
        <>
          <div className="px-6 lg:px-24 grid grid-cols-1 lg:grid-cols-3 gap-6 lg:gap-12 w-full lg:translate-y-10">
            <div className="bg-white shadow-xl p-12">
              <p className="color-main font-bold uppercase">Wersja próbna</p>
              <p className="text-5xl my-1 color-add">Bezpłatnie</p>
              <p className="color-add opacity-80 text-sm mb-4">
                Brak opłaty wstępnej
              </p>
              <p className="color-add opacity-80 text-lg mb-6">
                Skorzystaj z 14-dniowego okresu próbnego
              </p>
              <div className="flex flex-col gap-2">
                <div className="flex flex-row items-center justify-start gap-1">
                  <FaCheckCircle color="#ea5b0c" className="text-main" />
                  <p className="color-add">Limit 25 ofert</p>
                </div>
                <div className="flex flex-row items-center justify-start gap-1">
                  <FaCheckCircle color="#ea5b0c" className="text-main" />
                  <p className="color-add">Generowanie ofert</p>
                </div>
                <div className="flex flex-row items-center justify-start gap-1">
                  <FaCheckCircle color="#ea5b0c" className="text-main" />
                  <p className="color-add">Podgląd odczytania</p>
                </div>
                <div className="flex flex-row items-center justify-start gap-1">
                  <FaCheckCircle color="#ea5b0c" className="text-main" />
                  <p className="color-add">Baza ofert w jednym miejscu</p>
                </div>
                <div className="flex flex-row items-center justify-start gap-1 opacity-40">
                  <FaXing color="#ea5b0c" className="text-main" />
                  <p className="color-add">Indywidualny projekt oferty</p>
                </div>
                <div className="flex flex-row items-center justify-start gap-1 opacity-40">
                  <FaXing color="#ea5b0c" className="text-main" />
                  <p className="color-add">Aplikacja na Twojej subdomenie</p>
                </div>
              </div>
            </div>
            <div className="bg-white shadow-xl p-12">
              <p className="color-main font-bold uppercase">Podstawowy</p>
              <p className="text-5xl my-1 color-add">
                59 zł<span className="text-base"> / miesięcznie</span>
              </p>
              <p className="color-add opacity-80 text-sm mb-4">
                + 399 zł jednorazowa opłata wstępna
              </p>
              <p className="color-add opacity-80 text-lg mb-6">
                Wyróżnij i zorganizuj swoje oferty dla potencjalnych Klientów.
              </p>
              <div className="flex flex-col gap-2">
                <div className="flex flex-row items-center justify-start gap-1">
                  <FaCheckCircle color="#ea5b0c" className="text-main" />
                  <p className="color-add">Brak limitu ofert</p>
                </div>
                <div className="flex flex-row items-center justify-start gap-1">
                  <FaCheckCircle color="#ea5b0c" className="text-main" />
                  <p className="color-add">Generowanie ofert</p>
                </div>
                <div className="flex flex-row items-center justify-start gap-1">
                  <FaCheckCircle color="#ea5b0c" className="text-main" />
                  <p className="color-add">Podgląd odczytania</p>
                </div>
                <div className="flex flex-row items-center justify-start gap-1">
                  <FaCheckCircle color="#ea5b0c" className="text-main" />
                  <p className="color-add">Baza ofert w jednym miejscu</p>
                </div>
                <div className="flex flex-row items-center justify-start gap-1">
                  <FaCheckCircle color="#ea5b0c" className="text-main" />
                  <p className="color-add">Indywidualny projekt oferty</p>
                </div>
                <div className="flex flex-row items-center justify-start gap-1 opacity-40">
                  <FaXing color="#ea5b0c" className="text-main" />
                  <p className="color-add">Aplikacja na Twojej subdomenie</p>
                </div>
              </div>
            </div>
            <div className="bg-white shadow-xl p-12">
              <p className="color-main font-bold uppercase">Spersonalizowany</p>
              <p className="text-5xl my-1 color-add">
                99 zł<span className="text-base"> / miesięcznie</span>
              </p>
              <p className="color-add opacity-80 text-sm mb-4">
                + 399 zł jednorazowa opłata wstępna
              </p>
              <p className="color-add opacity-80 text-lg mb-6">
                Podkreśl swoją markę na własnej subdomenie.
              </p>
              <div className="flex flex-col gap-2">
                <div className="flex flex-row items-center justify-start gap-1">
                  <FaCheckCircle color="#ea5b0c" className="text-main" />
                  <p className="color-add">Brak limitu ofert</p>
                </div>
                <div className="flex flex-row items-center justify-start gap-1">
                  <FaCheckCircle color="#ea5b0c" className="text-main" />
                  <p className="color-add">Generowanie ofert</p>
                </div>
                <div className="flex flex-row items-center justify-start gap-1">
                  <FaCheckCircle color="#ea5b0c" className="text-main" />
                  <p className="color-add">Podgląd odczytania</p>
                </div>
                <div className="flex flex-row items-center justify-start gap-1">
                  <FaCheckCircle color="#ea5b0c" className="text-main" />
                  <p className="color-add">Baza ofert w jednym miejscu</p>
                </div>
                <div className="flex flex-row items-center justify-start gap-1">
                  <FaCheckCircle color="#ea5b0c" className="text-main" />
                  <p className="color-add">Indywidualny projekt oferty</p>
                </div>
                <div className="flex flex-row items-center justify-start gap-1">
                  <FaCheckCircle color="#ea5b0c" className="text-main" />
                  <p className="color-add">Aplikacja na Twojej subdomenie</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  );
}
