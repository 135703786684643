import Footer from "../compontents/common/footer";

import HeaderLeft from "../compontents/common/headerLeft";
import HeaderSlide from "../compontents/common/headerSlide";
import HeaderTop from "../compontents/common/headerTop";

import { useEffect, useState } from "react";
import Content from "../compontents/login/content";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const [showSlide, setShowSlide] = useState(false);
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/panel");
    }
  }, [isAuthenticated, navigate]);

  return (
    <>
      {showSlide && <HeaderSlide onClick={() => setShowSlide(false)} />}
      <HeaderTop />
      <div className="flex flex-row items-start justify-start h-screen">
        <HeaderLeft onClick={() => setShowSlide(true)} />
        <div className="w-full h-screen overflow-y-auto">
          <Content />
          <Footer />
        </div>
      </div>
    </>
  );
}
