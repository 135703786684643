import { Routes, Route, BrowserRouter } from "react-router-dom";
import "./index.css";

import Home from "./pages/Home";
import GroupCustomer from "./pages/GroupCustomer";
import Contact from "./pages/Contact";
import Login from "./pages/Login";
import Panel from "./pages/Panel";
import Form from "./pages/Form";
import OfferDetail from "./compontents/panel/offer";
import { AuthProvider } from "./context/AuthContext";
import ProtectedRoute from "./compontents/routes/ProtectedRoute";
import PostDetail from "./compontents/blog/postDetail";
import Settings from "./pages/Settings";
import Post from "./pages/Post";
import PolitykaPrywatnosci from "./compontents/documents/politykaPrywatnosci";
import RegulaminSerwisu from "./compontents/documents/regulaminSerwisu";
import ObowiazekInformacyjny from "./compontents/documents/obowiazekInformacyjny";
import DocumentPolitykaPrywatnosci from "./pages/PolitykaPrywatnosci";
import DocumentRegulaminSerwisu from "./pages/RegulaminSerwisu";
import DocumentObowiazekInformacyjny from "./pages/ObowiazekInformacyjny";

const App: React.FC = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/klienci-ifil" element={<GroupCustomer />} />
          <Route path="/kontakt" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/panel"
            element={<ProtectedRoute element={<Panel />} />}
          />
          <Route
            path="/panel/nowa-oferta"
            element={<ProtectedRoute element={<Form />} />}
          />
          <Route
            path="/panel/ustawienia"
            element={<ProtectedRoute element={<Settings />} />}
          />
          <Route path="/panel/:slug" element={<OfferDetail />} />{" "}
          <Route path="/post/:slug" element={<Post />} />
          <Route
            path="/polityka-prywatnosci"
            element={<DocumentPolitykaPrywatnosci />}
          />
          <Route
            path="/regulamin-serwisu"
            element={<DocumentRegulaminSerwisu />}
          />
          <Route
            path="/obowiazek-informacyjny"
            element={<DocumentObowiazekInformacyjny />}
          />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default App;
