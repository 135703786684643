import { useEffect, useRef } from "react";
import ContactForm from "./contactForm";

export default function Hero() {
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (svgRef.current) {
        svgRef.current.classList.add("active");
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (svgRef.current) {
        svgRef.current.classList.add("animate-bounce");
      }
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <section className="px-6 lg:px-24 py-6 lg:py-48 grid grid-cols-1 lg:grid-cols-5 gap-6 lg:gap-12">
      <div className="lg:col-span-3 flex flex-col items-start justify-center gap-6 lg:gap-12">
        <h2 className="bold text-[4vh] lg:text-[8vh] leading-none color-add">
          Porozmawiajmy
          <br />o Twoim procesie{" "}
          <span className="color-main bold">ofertowania</span>
        </h2>
        <p className="text-xl lg:text-3xl max-w-[550px] color-add">
          Masz jakieś pytania? Chcesz nawiązać współpracę? Masz pomysł na rozwój
          aplikacji? Skontaktuj się z nami!
        </p>
        <a
          className="flex flex-row items-center justify-start gap-2"
          href="mailto:hello@letsoffer.pl"
        >
          <svg
            width={35}
            viewBox="-0.5 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <path
                d="M9.00977 21.39H19.0098C20.0706 21.39 21.0881 20.9685 21.8382 20.2184C22.5883 19.4682 23.0098 18.4509 23.0098 17.39V7.39001C23.0098 6.32915 22.5883 5.31167 21.8382 4.56152C21.0881 3.81138 20.0706 3.39001 19.0098 3.39001H7.00977C5.9489 3.39001 4.93148 3.81138 4.18134 4.56152C3.43119 5.31167 3.00977 6.32915 3.00977 7.39001V12.39"
                stroke="#ea5b0c"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>{" "}
              <path
                d="M1.00977 18.39H11.0098"
                stroke="#ea5b0c"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>{" "}
              <path
                d="M1.00977 15.39H5.00977"
                stroke="#ea5b0c"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>{" "}
              <path
                d="M22.209 5.41992C16.599 16.0599 9.39906 16.0499 3.78906 5.41992"
                stroke="#ea5b0c"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>{" "}
            </g>
          </svg>
          <span className="text-2xl color-add">hello@letsoffer.pl</span>
        </a>
      </div>
      <div className="lg:col-span-2 flex flex-col items-center justify-center">
        <ContactForm />
      </div>
    </section>
  );
}
