import { useEffect, useState } from "react";
import { IoIosCloseCircle } from "react-icons/io";
import Notification from "./notify";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";
import { Notification as NotificationType } from "../../types/Notification";
import API_BASE_URL from "../../config";

interface Props {
  closePanel: () => void;
  onNotificationChange: (hasNotifications: boolean) => void;
}

export default function NotificationPanel({
  closePanel,
  onNotificationChange,
}: Props) {
  const [notifications, setNotifications] = useState<NotificationType[]>([]);
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      const token = localStorage.getItem("authToken");
      if (token) {
        axios
          .get(`${API_BASE_URL}/api/notifications/`, {
            headers: {
              Authorization: `Token ${token}`,
            },
          })
          .then((response) => {
            setNotifications(response.data);
            onNotificationChange(response.data.length > 0); // Notify parent about notification count
          })
          .catch((error) => console.error(error));
      } else {
        console.error("No auth token found");
      }
    }
  }, [isAuthenticated, onNotificationChange]);

  const handleDelete = (id: number) => {
    const token = localStorage.getItem("authToken");
    if (token) {
      axios
        .delete(`${API_BASE_URL}/api/notifications/${id}/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then(() => {
          setNotifications((prevNotifications) =>
            prevNotifications.filter((notification) => notification.id !== id)
          );
          onNotificationChange(notifications.length > 1); // Update parent about notification count after deletion
        })
        .catch((error) => console.error("Error deleting notification:", error));
    }
  };

  return (
    <div className="w-screen h-screen fixed z-[9999999999999999999999999999999] top-0 left-0">
      <div className="w-full h-full absolute bg-black opacity-60 z-40"></div>
      <div className="flex flex-col items-start justify-start w-[400px] bg-white fixed top-0 right-0 h-screen z-50">
        <IoIosCloseCircle
          onClick={() => closePanel()}
          className="color-main z-50 top-4 right-4 absolute text-4xl transition duration-300 ease-linear cursor-pointer hover:opacity-50"
        />
        <div className="flex flex-col items-start justify-start gap-6 w-full h-full relative py-12 px-6 overflow-y-auto">
          {notifications.length === 0 ? (
            <p className="color-main">Brak powiadomień</p>
          ) : (
            notifications.map((notification) => (
              <Notification
                key={notification.id}
                notification={notification}
                onDelete={handleDelete}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
}
