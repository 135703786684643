import { useState, useEffect } from "react";
import { IoIosEyeOff, IoMdEye, IoIosLink, IoIosClose } from "react-icons/io"; // Importujemy ikonę X
import axios from "axios";
import { useAuth } from "../../context/AuthContext";
import { Offer } from "../../types/Offer";
import { formatDate } from "../../utils/formatDate";
import { Tooltip } from "react-tooltip";
import API_BASE_URL from "../../config";

export default function Table() {
  const [offers, setOffers] = useState<Offer[]>([]);
  const { isAuthenticated } = useAuth();
  const [tooltipContent, setTooltipContent] = useState("Skopiuj link");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [offerToDelete, setOfferToDelete] = useState<Offer | null>(null);

  useEffect(() => {
    if (isAuthenticated) {
      const token = localStorage.getItem("authToken");
      if (token) {
        axios
          .get(`${API_BASE_URL}/api/offers/`, {
            headers: {
              Authorization: `Token ${token}`,
            },
          })
          .then((response) => setOffers(response.data))
          .catch((error) => console.error(error));
      } else {
        console.error("No auth token found");
      }
    }
  }, [isAuthenticated]);

  const handleCopyLink = (slug: string) => {
    const link = `http://letsoffer.pl/panel/${slug}`;
    navigator.clipboard.writeText(link).then(() => {
      setTooltipContent("Link skopiowany!");
      setTimeout(() => setTooltipContent("Skopiuj link"), 2000);
    });
  };

  const handleDeleteClick = (offer: Offer) => {
    setOfferToDelete(offer);
    setShowConfirmation(true);
  };

  const handleConfirmDelete = () => {
    if (offerToDelete) {
      const token = localStorage.getItem("authToken");
      axios
        .delete(`${API_BASE_URL}/api/offers/${offerToDelete.slug}/delete/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((response) => {
          setOffers(offers.filter((o) => o.slug !== offerToDelete.slug));
          setShowConfirmation(false);
          setOfferToDelete(null);
        })
        .catch((error) => console.error(error));
    }
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
    setOfferToDelete(null);
  };

  return (
    <section className="p-6 lg:p-12">
      <div className="overflow-x-auto">
        <table className="w-full min-w-max">
          <thead className="border-b border-[#ea5b0c]">
            <tr>
              <th className="color-main font-light text-xl pb-2 text-left lg:w-[30%] pr-6 lg:pr-0">
                Nazwa oferty
              </th>
              <th className="color-main font-light text-xl pb-2 text-left lg:w-[30%] pr-6 lg:pr-0">
                Odbiorca oferty
              </th>
              <th className="color-main font-light text-xl pb-2 text-left lg:w-[25%] pr-6 lg:pr-0">
                Data przygotowania oferty
              </th>
              <th className="pb-2 text-left lg:w-[5%] pr-6 lg:pr-0">
                <IoMdEye className="text-3xl color-main" />
              </th>
              <th className="pb-2 text-left lg:w-[5%] pr-6 lg:pr-0">
                <IoIosLink className="text-3xl color-main" />
              </th>
              <th className="pb-2 text-left lg:w-[5%] pr-6 lg:pr-0">
                {" "}
                {/* Dodana nowa kolumna */}
                <IoIosClose className="text-3xl color-main" />
              </th>
            </tr>
          </thead>
          <tbody className="overflow-x-auto">
            {offers.map((offer, index) => (
              <tr key={index} className="border-b border-gray-200">
                <td className="text-gray-500 font-light text-xl pr-6 lg:pr-0 py-1 text-left lg:w-[30%] transition duration-300 ease-linear cursor-pointer">
                  <a
                    className="hover:text-[#ea5b0c] transition duration-300 ease-linear"
                    href={`/panel/${offer.slug}`}
                  >
                    {offer.service_name}
                  </a>
                </td>
                <td className="text-gray-500 font-light text-xl pr-6 lg:pr-0 pb-1 text-left lg:w-[30%]">
                  {offer.client_name}
                </td>
                <td className="text-gray-500 font-light text-xl pr-6 lg:pr-0 py-1 text-left lg:w-[25%]">
                  {formatDate(offer.creation_date)}
                </td>
                <td className="py-1 text-left lg:w-[5%] g:pr-0">
                  {offer.viewed ? (
                    <IoMdEye className="text-3xl text-gray-600" />
                  ) : (
                    <IoIosEyeOff className="text-3xl text-gray-600" />
                  )}
                </td>
                <td className="py-1 text-left lg:w-[5%] pr-6 lg:pr-0">
                  <IoIosLink
                    className="text-3xl color-main cursor-pointer hover:opacity-50 transition duration-300 ease-linear"
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={tooltipContent}
                    onClick={() => handleCopyLink(offer.slug || "")}
                  />
                </td>
                <td className="py-1 text-left lg:w-[5%] pr-6 lg:pr-0">
                  {" "}
                  <IoIosClose
                    className="text-3xl color-main cursor-pointer hover:opacity-50 transition duration-300 ease-linear"
                    onClick={() => handleDeleteClick(offer)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showConfirmation && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg">
            <p className="text-lg mb-4">
              Czy na pewno chcesz usunąć tę ofertę?
            </p>
            <div className="flex justify-end gap-4 items-center">
              <button
                className="button-main-outlined"
                onClick={handleCancelDelete}
              >
                Anuluj
              </button>
              <button className="button-main" onClick={handleConfirmDelete}>
                Usuń
              </button>
            </div>
          </div>
        </div>
      )}
      <Tooltip id="my-tooltip" />
    </section>
  );
}
