import { IoIosWarning, IoIosCloseCircle } from "react-icons/io";

interface NotificationProps {
  notification: {
    id: number;
    message: string;
    offer: {
      slug: string;
    };
  };
  onDelete: (id: number) => void;
}

export default function Notification({
  notification,
  onDelete,
}: NotificationProps) {
  const authToken = localStorage.getItem("authToken");

  return (
    <div className="w-full shadow-md p-6 flex flex-row items-center justify-between gap-4">
      <div className="flex flex-row items-center gap-4">
        <IoIosWarning className="text-2xl color-main" />
        <div className="flex flex-col items-start justify-center w-full">
          <p>{notification.message}</p>
          <a
            href={`/panel/${notification.offer.slug}?token=${authToken}`} // Przekazujemy token w URL
            className="text-sm hover:opacity-50 transition duration-300 ease-linear color-main uppercase"
          >
            zobacz
          </a>
        </div>
      </div>
      <IoIosCloseCircle
        className="text-2xl text-gray-400 cursor-pointer hover:text-red-600 transition duration-300 ease-linear"
        onClick={() => onDelete(notification.id)}
      />
    </div>
  );
}
