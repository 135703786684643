import ContactFormSettings from "./contactData";
import InvoiceFormSettings from "./invoiceData";
import Subscription from "./subscription";
import Contact from "./contact";
import Ifil from "./ifil";

export default function Form() {
  return (
    <div className="w-full flex flex-col lg:flex-row gap-6 lg:gap-12 px-6 lg:px-12 pb-12 lg:pb-12">
      <div className="w-full flex flex-col gap-12">
        <ContactFormSettings />
        <InvoiceFormSettings />
      </div>
      <div className="w-full lg:w-1/2 flex flex-col gap-6 lg:gap-12">
        <Subscription />
        <Contact />
        <Ifil />
      </div>
    </div>
  );
}
