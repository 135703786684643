export default function Problems() {
  return (
    <section className="p-6 lg:px-24 lg:pb-24 lg:pt-12 bg-gray-50 flex flex-col items-center justify-center gap-12">
      <div className="flex flex-col items-start justify-start w-full">
        <h3 className="color-main text-xl uppercase">Nasze usługi w iFil</h3>
        <span className="block w-16 h-[2px] bg-main my-2"></span>
        <h2 className="text-2xl lg:text-[8vh] leading-none color-add mb-6 text-left">
          Skorzystaj z usług iFil i zyskaj bezpłatny dostęp do Let's Offer
        </h2>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-12 w-full">
        <div className="flex flex-col items-start justify-start p-6 lg:p-12 bg-white shadow-xl relative overflow-hidden group hover:-translate-y-2 transition duration-300 ease-linear">
          <img
            className="absolute top-2 right-2 opacity-10 z-10 w-36"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/lets-offer/sygnet-color.png"
            alt=""
          />
          <img
            className="w-16 mb-4 z-20"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/lets-offer/strony-internetowe.png"
            alt=""
          />
          <h5 className="color-add text-lg lg:text-2xl z-20 font-bold mb-2 group-hover:text-[#ea5b0c] transition duration-300 ease-linear">
            Strony internetowe
          </h5>
          <p className="text-base lg:text-lg z-20 color-add opacity-80">
            Tworzymy nowoczesne i responsywne strony internetowe, dostosowane do
            potrzeb i oczekiwań klientów.
          </p>
        </div>
        <div className="flex flex-col items-start justify-start p-6 lg:p-12 bg-white shadow-xl relative overflow-hidden group hover:-translate-y-2 transition duration-300 ease-linear">
          <img
            className="absolute top-2 right-2 opacity-10 z-10 w-36"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/lets-offer/sygnet-color.png"
            alt=""
          />
          <img
            className="w-16 mb-4 z-20"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/lets-offer/sklepy-internetowe.png"
            alt=""
          />
          <h5 className="color-add text-lg lg:text-2xl z-20 font-bold mb-2 group-hover:text-[#ea5b0c] transition duration-300 ease-linear">
            Sklepy on-line
          </h5>
          <p className="text-base lg:text-lg z-20 color-add opacity-80">
            Projektujemy i wdrażamy funkcjonalne i bezpieczne sklepy on-line,
            zintegrowane z systemami płatności i logistyki.
          </p>
        </div>
        <div className="flex flex-col items-start justify-start p-6 lg:p-12 bg-white shadow-xl relative overflow-hidden group hover:-translate-y-2 transition duration-300 ease-linear">
          <img
            className="absolute top-2 right-2 opacity-10 z-10 w-36"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/lets-offer/sygnet-color.png"
            alt=""
          />
          <img
            className="w-16 mb-4 z-20"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/lets-offer/aplikacje-internetowe.png"
            alt=""
          />
          <h5 className="color-add text-lg lg:text-2xl z-20 font-bold mb-2 group-hover:text-[#ea5b0c] transition duration-300 ease-linear">
            Aplikacje internetowe
          </h5>
          <p className="text-base lg:text-lg z-20 color-add opacity-80">
            Opracowujemy aplikacje na różne platformy i urządzenia,
            wykorzystując najnowsze technologie i narzędzia.
          </p>
        </div>
        <div className="flex flex-col items-start justify-start p-6 lg:p-12 bg-white shadow-xl relative overflow-hidden group hover:-translate-y-2 transition duration-300 ease-linear">
          <img
            className="absolute top-2 right-2 opacity-10 z-10 w-36"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/lets-offer/sygnet-color.png"
            alt=""
          />
          <img
            className="w-16 mb-4 z-20"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/lets-offer/crm.png"
            alt=""
          />
          <h5 className="color-add text-lg lg:text-2xl z-20 font-bold mb-2 group-hover:text-[#ea5b0c] transition duration-300 ease-linear">
            Systemy CRM
          </h5>
          <p className="text-base lg:text-lg z-20 color-add opacity-80">
            Projektujemy i implementujemy systemy CRM, które pomagają zarządzać
            relacjami z klientami, poprawiając komunikację, sprzedaż i
            lojalność.
          </p>
        </div>
        <div className="flex flex-col items-start justify-start p-6 lg:p-12 bg-white shadow-xl relative overflow-hidden group hover:-translate-y-2 transition duration-300 ease-linear">
          <img
            className="absolute top-2 right-2 opacity-10 z-10 w-36"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/lets-offer/sygnet-color.png"
            alt=""
          />
          <img
            className="w-16 mb-4 z-20"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/lets-offer/ai.png"
            alt=""
          />
          <h5 className="color-add text-lg lg:text-2xl z-20 font-bold mb-2 group-hover:text-[#ea5b0c] transition duration-300 ease-linear">
            Integracje AI
          </h5>
          <p className="text-base lg:text-lg z-20 color-add opacity-80">
            Wprowadzamy sztuczną inteligencję do twojego biznesu, zwiększając
            wydajność Twojego biznesu.
          </p>
        </div>
        <div className="flex flex-col items-start justify-start p-6 lg:p-12 bg-white shadow-xl relative overflow-hidden group hover:-translate-y-2 transition duration-300 ease-linear">
          <img
            className="absolute top-2 right-2 opacity-10 z-10 w-36"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/lets-offer/sygnet-color.png"
            alt=""
          />
          <img
            className="w-16 mb-4 z-20"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/lets-offer/interfejsy.png"
            alt=""
          />
          <h5 className="color-add text-lg lg:text-2xl z-20 font-bold mb-2 group-hover:text-[#ea5b0c] transition duration-300 ease-linear">
            Interfejsy graficzne
          </h5>
          <p className="text-base lg:text-lg z-20 color-add opacity-80">
            Zajmujemy się projektowaniem interfejsów użytkownika, dbając o
            estetykę, ergonomię i użyteczność.
          </p>
        </div>
        <div className="flex flex-col items-start justify-start p-6 lg:p-12 bg-white shadow-xl relative overflow-hidden group hover:-translate-y-2 transition duration-300 ease-linear">
          <img
            className="absolute top-2 right-2 opacity-10 z-10 w-36"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/lets-offer/sygnet-color.png"
            alt=""
          />
          <img
            className="w-16 mb-4 z-20"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/lets-offer/mvp.png"
            alt=""
          />
          <h5 className="color-add text-lg lg:text-2xl z-20 font-bold mb-2 group-hover:text-[#ea5b0c] transition duration-300 ease-linear">
            MVP
          </h5>
          <p className="text-base lg:text-lg z-20 color-add opacity-80">
            Wdrażamy minimalną wersję produktu, która pozwoli ci zweryfikować
            potencjalny sukces projektu.
          </p>
        </div>
        <div className="flex flex-col items-start justify-start p-6 lg:p-12 bg-white shadow-xl relative overflow-hidden group hover:-translate-y-2 transition duration-300 ease-linear">
          <img
            className="absolute top-2 right-2 opacity-10 z-10 w-36"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/lets-offer/sygnet-color.png"
            alt=""
          />
          <img
            className="w-16 mb-4 z-20"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/lets-offer/wizerunek.png"
            alt=""
          />
          <h5 className="color-add text-lg lg:text-2xl z-20 font-bold mb-2 group-hover:text-[#ea5b0c] transition duration-300 ease-linear">
            Odświeżenie wizerunku
          </h5>
          <p className="text-base lg:text-lg z-20 color-add opacity-80">
            Odświeżymy Twoją identyfikację wizualną oraz interfejsy wszystkich
            Twoich produktów, aby Twoja firma nie stała za konkurencją.
          </p>
        </div>
      </div>
      <a href="https://ifil.pl">
        <button className="button-main">Dowiedz się więcej</button>
      </a>
    </section>
  );
}
