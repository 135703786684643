import React, { useState, useEffect } from "react";
import { FaQuestionCircle } from "react-icons/fa";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";
import { Offer } from "../../types/Offer";
import { Tooltip } from "react-tooltip";
import API_BASE_URL from "../../config";

export default function Stats() {
  const [offers, setOffers] = useState<Offer[]>([]);
  const { isAuthenticated } = useAuth();
  const [totalOffers, setTotalOffers] = useState(0);
  const [openedOffers, setOpenedOffers] = useState(0);
  const [openRate, setOpenRate] = useState(0);

  useEffect(() => {
    if (isAuthenticated) {
      const token = localStorage.getItem("authToken");
      if (token) {
        axios
          .get(`${API_BASE_URL}/api/offers`, {
            headers: {
              Authorization: `Token ${token}`,
            },
          })
          .then((response) => {
            const offersData = response.data;
            setOffers(offersData);

            const total = offersData.length;
            const opened = offersData.filter(
              (offer: Offer) => offer.viewed
            ).length;
            const rate = total > 0 ? Math.round((opened / total) * 100) : 0;

            setTotalOffers(total);
            setOpenedOffers(opened);
            setOpenRate(rate);
          })
          .catch((error) => console.error(error));
      } else {
        console.error("No auth token found");
      }
    }
  }, [isAuthenticated]);

  return (
    <section className="px-6 lg:px-12 gap-6 grid grid-cols-1 lg:grid-cols-3">
      <div className="shadow-lg bg-white p-6 border border-gray-100 flex flex-row items-center justify-between">
        <p className="text-5xl color-main">
          {totalOffers}{" "}
          <span className="text-lg text-gray-500">wszystkich ofert</span>
        </p>
        <FaQuestionCircle
          className="text-2xl color-main cursor-pointer hover:opacity-50 transition duration-300 ease-linear"
          data-tooltip-id="all"
          data-tooltip-content="Wszystkie Twoje oferty."
        />
      </div>
      <div className="shadow-lg bg-white p-6 border border-gray-100 flex flex-row items-center justify-between">
        <p className="text-5xl color-main">
          {openedOffers}{" "}
          <span className="text-lg text-gray-500">otworzonych ofert</span>
        </p>
        <FaQuestionCircle
          className="text-2xl color-main cursor-pointer hover:opacity-50 transition duration-300 ease-linear"
          data-tooltip-id="open"
          data-tooltip-content="Ilość otwartych ofert."
        />
      </div>
      <div className="shadow-lg bg-white p-6 border border-gray-100 flex flex-row items-center justify-between">
        <p className="text-5xl color-main">
          {openRate}%{" "}
          <span className="text-lg text-gray-500">współczynnik otwarcia</span>
        </p>
        <FaQuestionCircle
          className="text-2xl color-main cursor-pointer hover:opacity-50 transition duration-300 ease-linear"
          data-tooltip-id="percent"
          data-tooltip-content="Procent otworzonych ofert."
        />
      </div>
      <Tooltip id="all" />
      <Tooltip id="open" />
      <Tooltip id="percent" />
    </section>
  );
}
