import { useState } from "react";

import HeaderLeft from "../compontents/common/headerLeft";
import HeaderSlide from "../compontents/common/headerSlide";

import Header from "../compontents/panel/header";
import OfferForm from "../compontents/form/form";

export default function Form() {
  const [showSlide, setShowSlide] = useState(false);

  return (
    <>
      {showSlide && <HeaderSlide onClick={() => setShowSlide(false)} />}
      <div className="flex flex-row items-start justify-start lg:h-screen">
        <HeaderLeft onClick={() => setShowSlide(true)} />
        <div className="w-full h-screen overflow-y-auto">
          <Header />
          <OfferForm />
        </div>
      </div>
    </>
  );
}
