import { useState, useEffect } from "react";
import { CiCreditCard1 } from "react-icons/ci";
import API_BASE_URL from "../../config";

export default function Subscription() {
  const [subscriptionPlan, setSubscriptionPlan] = useState(
    "Bezpłatny okres próbny"
  );
  const [daysRemaining, setDaysRemaining] = useState(0);
  const [requestSent, setRequestSent] = useState(false);

  useEffect(() => {
    fetchSubscriptionData();
  }, []);

  const fetchSubscriptionData = async () => {
    const token = localStorage.getItem("authToken");

    if (!token) {
      console.error("No token found. Please log in.");
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/api/auth/profile/`, {
        method: "GET",
        headers: {
          Authorization: `Token ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setSubscriptionPlan(data.subscription_plan || "Bezpłatny okres próbny");

        if (data.subscription_expiry_date) {
          const expiryDate = new Date(data.subscription_expiry_date);
          const today = new Date();
          const diffTime = Math.abs(expiryDate.getTime() - today.getTime());
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          setDaysRemaining(diffDays);
        } else {
          setDaysRemaining(0);
        }
      } else {
        console.error("Failed to fetch subscription data");
      }
    } catch (error) {
      console.error("Error fetching subscription data:", error);
    }
  };

  const handleUpgradeRequest = async () => {
    const token = localStorage.getItem("authToken");

    if (!token) {
      console.error("No token found. Please log in.");
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/api/mailing/upgrade/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });

      if (response.ok) {
        setRequestSent(true);
      } else {
        console.error("Failed to send upgrade request");
        alert("Wystąpił błąd przy wysyłaniu prośby. Spróbuj ponownie.");
      }
    } catch (error) {
      console.error("Error sending upgrade request:", error);
    }
  };

  return (
    <div className="flex flex-col p-6 lg:p-12 shadow-main items-start justify-start bg-main gap-4">
      <div className="flex flex-row items-center justify-start gap-2">
        <CiCreditCard1 className="text-4xl text-white" />
        <h2 className="text-4xl text-white">Twój plan</h2>
      </div>
      <p className="text-2xl text-white font-semibold">{subscriptionPlan}</p>
      <p className="text-xl text-white">Pozostało {daysRemaining} dni</p>

      {requestSent ? (
        <p className="text-white mt-4">
          Dziękujemy, wkrótce otrzymasz link do płatności.
        </p>
      ) : (
        <button onClick={handleUpgradeRequest} className="button-white">
          Zamów wyższy plan
        </button>
      )}
    </div>
  );
}
