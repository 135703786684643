import { useState } from "react";
import { AiOutlinePlayCircle, AiOutlineClose } from "react-icons/ai";

export default function HowItWorks() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <section
        id="jak-to-dziala"
        className="p-6 lg:p-24 bg-gray-50 flex flex-col-reverse lg:grid lg:grid-cols-2 gap-6 lg:gap-12"
      >
        <div className="group w-full hidden lg:flex flex-col items-center justify-center relative">
          <video
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/lets-offer/instuction-video.mov"
            controls={false}
            muted
            className="block shadow-xl group-hover:shadow-2xl group-hover:scale-105 transition duration-300 ease-linear"
          ></video>
          <button
            onClick={openModal}
            className="absolute inset-0 flex items-center justify-center"
            aria-label="Play Video"
          >
            <AiOutlinePlayCircle className="color-main text-6xl group-hover:opacity-60 transition duration-300 ease-linear" />
          </button>
        </div>
        <div className="w-full flex flex-col items-start justify-center">
          <h3 className="color-main text-xl uppercase">Jak to działa?</h3>
          <span className="block w-16 h-[2px] bg-main my-2"></span>
          <h2 className="text-[4vh] lg:text-[7vh] leading-none color-add mb-6">
            Spójna oferta gotowa w 90 sekund
          </h2>
          <p className="text-[2.5vh] color-add opacity-80 mb-6">
            Wybierz szablon, wprowadź parametry m.in cenę oraz czas realizacji.
            Wygeneruj link do oferty, która będzie spójna z Twoją identyfikacją
            wizualną. Prześlij ją bezpośrednio do Klienta oraz kontroluj
            wszystkie oferty w jednym miejscu.
          </p>
          <a href="/login">
            <button className="button-main uppercase tracking-[1px]">
              Skorzystaj z demo
            </button>
          </a>
        </div>
      </section>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-[9999999999999]">
          <button
            onClick={closeModal}
            className="absolute top-4 right-4 bg-main text-white text-4xl p-2 rounded-full"
          >
            <AiOutlineClose />
          </button>
          <div className="relative bg-white h-3/4 max-h-full">
            <video
              src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/lets-offer/instuction-video.mov"
              controls
              autoPlay
              className="w-full h-full"
            ></video>
          </div>
        </div>
      )}
    </>
  );
}
