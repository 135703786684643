import { CiCreditCard1 } from "react-icons/ci";
import { MdOutlineSupportAgent } from "react-icons/md";

export default function Contact() {
  return (
    <div className="flex flex-col p-6 lg:p-12 shadow-main items-start justify-start bg-white gap-4">
      <div className="flex flex-col lg:flex-row items-start lg:items-center justify-start gap-2">
        <MdOutlineSupportAgent className="text-4xl color-main" />
        <h2 className="text-4xl color-main">Obsługa Klienta</h2>
      </div>
      <p className="text-xl color-main">Masz pytania? Napisz do nas!</p>
      <a href="mailto:hello@letsoffer.pl" className="text-xl">
        <button className="button-main flex flex-row items-center justify-center gap-2">
          <span>hello@letsoffer.pl</span>
        </button>
      </a>
    </div>
  );
}
