import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import API_BASE_URL from "../../config";
import { Offer } from "../../types/Offer";
import { TemplateComponentProps } from "../../types/TemplateComponentProps";

const OfferDetail: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [offer, setOffer] = useState<Offer | null>(null);
  const [TemplateComponent, setTemplateComponent] =
    useState<React.FC<TemplateComponentProps> | null>(null);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/api/offers/${slug}/`)
      .then((response) => {
        const fetchedOffer = response.data;
        console.log("Fetched offer:", fetchedOffer);
        setOffer(fetchedOffer);

        if (fetchedOffer.template) {
          const templateFileName = fetchedOffer.template.file_name;
          import(`../../templates/${templateFileName}`)
            .then((component) => setTemplateComponent(() => component.default))
            .catch((error) => console.error("Error loading template:", error));
        } else {
          console.error("Template not found in fetched offer");
        }
      })
      .catch((error) => console.error("Error fetching offer:", error));
  }, [slug]);

  if (!offer || !TemplateComponent) return <div>Loading...</div>;

  return <TemplateComponent offer={offer} />;
};

export default OfferDetail;
