import Footer from "../compontents/common/footer";

import HeaderLeft from "../compontents/common/headerLeft";
import HeaderSlide from "../compontents/common/headerSlide";
import HeaderTop from "../compontents/common/headerTop";

import { useState } from "react";
import PostDetail from "../compontents/blog/postDetail";

export default function Post() {
  const [showSlide, setShowSlide] = useState(false);

  return (
    <>
      {showSlide && <HeaderSlide onClick={() => setShowSlide(false)} />}
      <HeaderTop />
      <div className="flex flex-row items-start justify-start h-screen">
        <HeaderLeft onClick={() => setShowSlide(true)} />
        <div className="w-full h-screen overflow-y-auto">
          <div className="max-w-[800px] mx-auto py-6 lg:pt-48">
            <PostDetail />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
