import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import API_BASE_URL from "../../config";

const Content: React.FC = () => {
  const { login: authLogin } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [login, setLogin] = useState(true);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const mode = queryParams.get("mode");

    if (mode === "register") {
      setLogin(false);
    }
  }, [location.search]);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);

    const payload = {
      username: formData.get("username"),
      password: formData.get("password"),
    };
    // console.log("Sending payload:", payload);

    try {
      const response = await fetch(`${API_BASE_URL}/api/auth/login/`, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        // console.log(`Received token: ${data.token}`);
        authLogin(data.token);
        navigate("/panel");
      } else {
        const errorData = await response.json();
        // console.error("Error data:", errorData);
        alert("Błędne dane logowania");
      }
    } catch (error) {
      // console.error("Error during login:", error);
    }
  };

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);

    const data = {
      username: formData.get("username"),
      password1: formData.get("password1"),
      password2: formData.get("password2"),
      gdpr: formData.get("gdpr"),
    };

    // console.log("Registration data:", data);

    try {
      const response = await fetch(`${API_BASE_URL}/api/auth/register/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        // console.log(`Received token: ${data.token}`);
        authLogin(data.token);
        setLogin(true);
      } else {
        const result = await response.json();
        alert(`Błąd rejestracji: ${JSON.stringify(result.errors)}`);
      }
    } catch (error) {
      // console.error("Error during registration:", error);
    }
  };

  return (
    <section className="flex flex-col items-center justify-center p-6 lg:p-56">
      <div className="bg-white p-6 lg:p-12 shadow-2xl w-full lg:w-[400px] flex flex-col gap-4">
        <div className="flex flex-row items-center justify-center rounded-full border border-[#ea5b0c] p-[5px] gap-[5px]">
          <button
            onClick={() => setLogin(true)}
            className={
              login
                ? "rounded-full py-1 w-full text-white bg-[#ea5b0c]"
                : "rounded-full py-1 w-full color-add bg-transparent hover:bg-gray-100 transition duration-300 ease-linear"
            }
          >
            Logowanie
          </button>
          <button
            onClick={() => setLogin(false)}
            className={
              login
                ? "rounded-full py-1 w-full color-add bg-transparent hover:bg-gray-100 transition duration-300 ease-linear"
                : "rounded-full py-1 w-full text-white bg-[#ea5b0c]"
            }
          >
            Rejestracja
          </button>
        </div>
        {login && (
          <>
            <form onSubmit={handleLogin} className="flex flex-col gap-4">
              <input
                type="text"
                name="username"
                className="border-b-[1px] bg-transparent p-2 border-gray-400 outline-none focus:border-[#ea5b0c] transition duration-300 ease-linear focus:placeholder:opacity-50 placeholder:transition placeholder:duration-300 placeholder:ease-linear"
                placeholder="Adres e-mail"
              />
              <input
                type="password"
                name="password"
                className="border-b-[1px] bg-transparent p-2 border-gray-400 outline-none focus:border-[#ea5b0c] transition duration-300 ease-linear focus:placeholder:opacity-50 placeholder:transition placeholder:duration-300 placeholder:ease-linear"
                placeholder="Hasło"
              />
              <button
                type="submit"
                className="button-main uppercase tracking-[1px] mt-4"
              >
                Zaloguj się
              </button>
              {/* <div className="button-disabled uppercase tracking-[1px] mt-4">
                Już wkrótce
              </div> */}
            </form>
            {/* <a
              className="text-center opacity-40 hover:opacity-100 hover:text-[#ea5b0c] transition duration-300 ease-linear"
              href=""
            >
              Nie pamiętam hasła
            </a> */}
          </>
        )}
        {!login && (
          <form onSubmit={handleRegister} className="flex flex-col gap-4">
            <input
              type="text"
              name="username"
              className="border-b-[1px] bg-transparent p-2 border-gray-400 outline-none focus:border-[#ea5b0c] transition duration-300 ease-linear focus:placeholder:opacity-50 placeholder:transition placeholder:duration-300 placeholder:ease-linear"
              placeholder="Adres e-mail"
            />
            <input
              type="password"
              name="password1"
              className="border-b-[1px] bg-transparent p-2 border-gray-400 outline-none focus:border-[#ea5b0c] transition duration-300 ease-linear focus:placeholder:opacity-50 placeholder:transition placeholder:duration-300 placeholder:ease-linear"
              placeholder="Hasło"
            />
            <input
              type="password"
              name="password2"
              className="border-b-[1px] bg-transparent p-2 border-gray-400 outline-none focus:border-[#ea5b0c] transition duration-300 ease-linear focus:placeholder:opacity-50 placeholder:transition placeholder:duration-300 placeholder:ease-linear"
              placeholder="Powtórz hasło"
            />
            <div className="flex flex-row items-start justify-start gap-2">
              <input
                type="checkbox"
                id="gdpr"
                name="gdpr"
                className="mt-1 border-none outline-none"
              />
              <label
                htmlFor="gdpr"
                className="text-xs opacity-40 cursor-pointer"
              >
                Oświadczam, że zapoznałem się z Regulaminem Serwisu, Polityką
                Prywatności, Obowiązkiem informacyjnym oraz akceptuję założenia
                niniejszych dokumentów.
              </label>
            </div>
            <button
              type="submit"
              className="button-main uppercase tracking-[1px] mt-4"
            >
              Zarejestruj się
            </button>
            {/* <div className="button-disabled uppercase tracking-[1px] mt-4">
              Już wkrótce
            </div> */}
          </form>
        )}
      </div>
    </section>
  );
};

export default Content;
