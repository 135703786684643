export default function Problems() {
  return (
    <section
      id="dlaczego-warto"
      className="px-6 lg:px-24 pb-6 lg:pb-24 pt-6 lg:pt-12 bg-gray-50 flex flex-col items-center justify-center gap-12"
    >
      <div className="flex flex-col items-center justify-center w-full">
        <h3 className="color-main text-xl uppercase">Dlaczego warto?</h3>
        <span className="block w-16 h-[2px] bg-main my-2"></span>
        <h2 className="text-[4vh] lg:text-[8vh] leading-none color-add mb-6 text-center">
          Rozwiązujemy problemy procesu sprzedaży
        </h2>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 lg:gap-12 w-full">
        <div className="flex flex-col items-start justify-start p-12 bg-white shadow-xl relative overflow-hidden group hover:-translate-y-2 transition duration-300 ease-linear">
          <img
            className="absolute top-2 right-2 opacity-10 z-10 w-36"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/lets-offer/sygnet-color.png"
            alt=""
          />
          <img
            className="w-16 mb-4 z-20"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/lets-offer/rozwiazujemy-problemy-1.png"
            alt=""
          />
          <h5 className="color-add text-2xl z-20 font-bold mb-2 group-hover:text-[#ea5b0c] transition duration-300 ease-linear">
            Nie zgub swoich ofert
          </h5>
          <p className="text-lg z-20 color-add opacity-80">
            Twoje wszystkie oferty posortowane w jednym miejscu.
          </p>
        </div>
        <div className="flex flex-col items-start justify-start p-12 bg-white shadow-xl relative overflow-hidden group hover:-translate-y-2 transition duration-300 ease-linear">
          <img
            className="absolute top-2 right-2 opacity-10 z-10 w-36"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/lets-offer/sygnet-color.png"
            alt=""
          />
          <img
            className="w-16 mb-4 z-20"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/lets-offer/rozwiazujemy-problemy-2.png"
            alt=""
          />
          <h5 className="color-add text-2xl z-20 font-bold mb-2 group-hover:text-[#ea5b0c] transition duration-300 ease-linear">
            Przyspiesz proces
          </h5>
          <p className="text-lg z-20 color-add opacity-80">
            Uzupełniasz formularz i Twoja oferta jest gotowa.
          </p>
        </div>
        <div className="flex flex-col items-start justify-start p-12 bg-white shadow-xl relative overflow-hidden group hover:-translate-y-2 transition duration-300 ease-linear">
          <img
            className="absolute top-2 right-2 opacity-10 z-10 w-36"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/lets-offer/sygnet-color.png"
            alt=""
          />
          <img
            className="w-16 mb-4 z-20"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/lets-offer/rozwiazujemy-problemy-3.png"
            alt=""
          />
          <h5 className="color-add text-2xl z-20 font-bold mb-2 group-hover:text-[#ea5b0c] transition duration-300 ease-linear">
            Sprawdź zainteresowanie
          </h5>
          <p className="text-lg z-20 color-add opacity-80">
            Dowiesz się kto otworzył już Twoją ofertę.
          </p>
        </div>
      </div>
    </section>
  );
}
