import React, { useState, useRef, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { CgMenuRightAlt } from "react-icons/cg";
import { IoIosCloseCircle, IoMdLogIn } from "react-icons/io";
import { FaQuestionCircle, FaRegUser } from "react-icons/fa";
import { CiUser } from "react-icons/ci";

const MarqueeAnimation = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
`;

const MarqueeContainer = styled.div`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
`;

const MarqueeContent = styled.div<{ duration: number }>`
  display: inline-block;
  animation: ${MarqueeAnimation} linear infinite;
  animation-duration: ${({ duration }) => duration}s;
`;

export default function HeaderTop() {
  const [header, setHeader] = useState(true);
  const [mobileHeader, setMobileHeader] = useState(false);
  const marqueeRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    setHeader(false);
  };

  useEffect(() => {
    const marquee = marqueeRef.current;
    if (marquee) {
      const speed = 50;
      const animationDuration = marquee.scrollWidth / speed;
      marquee.style.animationDuration = `${animationDuration}s`;
    }
  }, []);
  return (
    <>
      <div className="lg:hidden w-full bg-gray-50 p-2 flex flex-row items-center justify-between">
        <MarqueeContainer>
          <MarqueeContent ref={marqueeRef} duration={50}>
            <div className="flex flex-row items-center justify-center gap-2">
              <p>Skorzystaj z bezpłatnego dostępu dla Klientów iFil Group!</p>
              <a
                className="uppercase font-bold animate-pulse cursor-pointer color-main"
                href="/klienci-ifil"
              >
                Dowiedz się więcej
              </a>
            </div>
          </MarqueeContent>
        </MarqueeContainer>
      </div>
      <header className="lg:hidden w-full bg-main p-2 flex flex-row items-center justify-between">
        <img
          className="h-6"
          src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/lets-offer/logo-white.png"
          alt=""
        />
        <CgMenuRightAlt
          onClick={() => setMobileHeader(true)}
          className="cursor-pointer text-4xl text-white"
        />
      </header>
      {mobileHeader && (
        <div className="fixed w-screen h-screen bg-main top-0 z-[99999999999]">
          <nav className="w-full h-full relative flex flex-col items-center justify-center gap-2 p-12">
            <IoIosCloseCircle
              onClick={() => setMobileHeader(false)}
              className="cursor-pointer text-4xl text-white top-2 right-2 absolute"
            />
            <a
              onClick={() => setMobileHeader(false)}
              className="text-white text-2xl"
              href="/"
            >
              Home
            </a>
            <a
              onClick={() => setMobileHeader(false)}
              className="text-white text-2xl"
              href="/#funkcjonalnosci"
            >
              Funkcjonalności
            </a>
            <a
              onClick={() => setMobileHeader(false)}
              className="text-white text-2xl"
              href="/#cennik"
            >
              Cennik
            </a>
            <a
              onClick={() => setMobileHeader(false)}
              className="text-white text-2xl"
              href="/#case-study"
            >
              Case studies
            </a>
            <a
              onClick={() => setMobileHeader(false)}
              className="text-white text-2xl"
              href="/kontakt"
            >
              Kontakt
            </a>
            <a
              className="w-full mt-6"
              onClick={() => setMobileHeader(false)}
              href="/login?mode=register"
            >
              <button className="button-white flex flex-row items-center justify-center gap-2 uppercase tracking-[1px] w-full">
                <span>Załóż bezpłatne konto</span>
                <FaRegUser className="color-main text-lg" />
              </button>
            </a>
            <a
              className="w-full"
              onClick={() => setMobileHeader(false)}
              href="/login"
            >
              <button className="button-add flex flex-row items-center justify-center gap-2 uppercase tracking-[1px] w-full">
                <span>Zaloguj się</span>
                <IoMdLogIn className="text-white text-xl" />
              </button>
            </a>
          </nav>
        </div>
      )}
      <div className="absolute top-0 right-0 hidden lg:flex flex-col bg-gray-50 w-[100%] z-[9999999]">
        <div
          className={
            header
              ? "bg-main py-2 flex flex-row gap-2 items-center justify-center text-white relative"
              : "hidden"
          }
        >
          <p>Skorzystaj z bezpłatnego dostępu dla Klientów iFil Group!</p>
          <a
            className="uppercase font-bold animate-pulse cursor-pointer"
            href="/klienci-ifil"
          >
            Dowiedz się więcej
          </a>
          <FaQuestionCircle className="text-xl text-white" />
        </div>
        <div>
          <nav className="py-4 px-24 flex flex-row items-center justify-end gap-6 text-base uppercase tracking-[1px]">
            <a className="underline-main" href="/">
              Home
            </a>
            <a className="underline-main" href="/#funkcjonalnosci">
              Funkcjonalności
            </a>
            <a className="underline-main" href="/#cennik">
              Cennik
            </a>
            <a className="underline-main" href="/#case-study">
              Case studies
            </a>
            <a className="underline-main" href="/kontakt">
              Kontakt
            </a>
            <a href="/login?mode=register">
              <button className="button-main uppercase tracking-[1px]">
                <span>Załóż bezpłatne konto</span>
              </button>
            </a>
            <a className="group" href="/login">
              <button className="button-main-outlined uppercase tracking-[1px]">
                <span>Logowanie</span>
                <IoMdLogIn className="color-main group-hover:text-white transition duration-100 ease-linear text-xl" />
              </button>
            </a>
          </nav>
        </div>
      </div>
    </>
  );
}
