import { useState } from "react";
import { AiOutlinePlayCircle, AiOutlineClose } from "react-icons/ai";

export default function Process() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <section
        id="proces"
        className="p-6 lg:px-24 lg:pt-0 lg:pb-24 bg-gray-50 grid grid-cols-1 lg:grid-cols-3 gap-6 lg:gap-12"
      >
        <div className="shadow-xl bg-main p-6 lg:p-12">
          <div className="color-main bg-white font-semibold text-2xl flex flex-col items-center justify-center rounded-full w-[50px] h-[50px] mb-4">
            <p>1</p>
          </div>
          <h5 className="text-white text-2xl z-20 font-bold mb-2 group-hover:text-[#ea5b0c] transition duration-300 ease-linear">
            Zarejestruj się i testuj
          </h5>
          <p className="text-lg z-20 opacity-80 text-white">
            Załóż bezpłatne konto i testuj 14 dni za darmo. Utwórz do 25 ofert!
            Otrzymasz pełen dostęp do aplikacji. Karta płatnicza{" "}
            <strong>nie jest</strong> wymagana.
          </p>
        </div>
        <div className="shadow-xl bg-white p-6 lg:p-12">
          <div className="bg-main text-white font-semibold text-2xl flex flex-col items-center justify-center rounded-full w-[50px] h-[50px] mb-4">
            <p>2</p>
          </div>
          <h5 className="color-add text-2xl z-20 font-bold mb-2 group-hover:text-[#ea5b0c] transition duration-300 ease-linear">
            Zamów wyższy plan
          </h5>
          <p className="text-lg z-20 color-add opacity-80">
            W panelu Klienta zamów wyższy plan. W ramach niego przygotujemy dla
            Ciebie <strong>indywidualny szablon</strong> oferty spójny z Twoją
            firmą.
          </p>
        </div>
        <div className="shadow-xl bg-white p-6 lg:p-12">
          <div className="bg-main text-white font-semibold text-2xl flex flex-col items-center justify-center rounded-full w-[50px] h-[50px] mb-4">
            <p>3</p>
          </div>{" "}
          <h5 className="color-add text-2xl z-20 font-bold mb-2 group-hover:text-[#ea5b0c] transition duration-300 ease-linear">
            Zwiększaj sprzedaż
          </h5>
          <p className="text-lg z-20 color-add opacity-80">
            Korzystaj w pełni z Let's Offer. Twórz oferty{" "}
            <strong>spójne z Twoją identyfikacją</strong> oraz{" "}
            <strong>stroną internetową</strong>. Wyróżnij się na tle
            konkurencji!
          </p>
        </div>
      </section>
    </>
  );
}
