import Form from "../compontents/settings/form";
import Header from "../compontents/panel/header";
import HeaderLeft from "../compontents/common/headerLeft";
import HeaderSlide from "../compontents/common/headerSlide";
import { useState } from "react";

function Settings() {
  const [showSlide, setShowSlide] = useState(false);

  return (
    <>
      {showSlide && <HeaderSlide onClick={() => setShowSlide(false)} />}
      <div className="flex flex-row items-start justify-start h-screen">
        <HeaderLeft onClick={() => setShowSlide(true)} />
        <div className="w-full h-screen overflow-y-auto">
          <Header />
          <Form />
        </div>
      </div>
    </>
  );
}

export default Settings;
