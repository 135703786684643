import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import API_BASE_URL from "../../config";

interface Post {
  id: number;
  title: string;
  slug: string;
  content: string;
  created_at: string;
  updated_at: string;
  type: string;
  category: {
    id: number;
    name: string;
    slug: string;
  };
}

const PostDetail: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [post, setPost] = useState<Post | null>(null);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/api/blog/posts/${slug}/`)
      .then((response) => setPost(response.data))
      .catch((error) => console.error("Error fetching post:", error));
  }, [slug]);

  if (!post) return <div>Loading...</div>;

  return (
    <div>
      <h1 className="text-4xl color-add">{post.title}</h1>
      <h2 className="text-xl color-main uppercase mb-6">{post.type}</h2>
      <div
        className="text-lg flex flex-col gap-2 text-box-casestudy"
        dangerouslySetInnerHTML={{ __html: post.content }}
      />
    </div>
  );
};

export default PostDetail;
