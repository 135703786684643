import { IoCloseCircleOutline } from "react-icons/io5";

interface Props {
  onClick: () => void;
}

export default function HeaderSlide({ onClick }: Props) {
  return (
    <div className="w-screen h-screen fixed z-[999999999] bg-main hidden lg:flex">
      <div className="w-full h-full relative flex flex-col items-center justify-start">
        <IoCloseCircleOutline
          onClick={onClick}
          className="cursor-pointer absolute text-white text-4xl top-6 left-4 z-50"
        />

        <div className="gap-2 flex flex-col items-start justify-center w-full h-full p-24">
          <a
            onClick={onClick}
            className="text-5xl text-white underline-white leading-[1.5em]"
            href="/"
          >
            Home
          </a>
          <a
            onClick={onClick}
            className="text-5xl text-white underline-white leading-[1.5em]"
            href="/#co-to-jest"
          >
            Co to jest
          </a>
          <a
            onClick={onClick}
            className="text-5xl text-white underline-white leading-[1.5em]"
            href="/#jak-to-dziala"
          >
            Jak to działa
          </a>
          <a
            onClick={onClick}
            className="text-5xl text-white underline-white leading-[1.5em]"
            href="/#dlaczego-warto"
          >
            Dlaczego warto
          </a>
          <a
            onClick={onClick}
            className="text-5xl text-white underline-white leading-[1.5em]"
            href="/#faq"
          >
            Najczęściej zadawane pytania
          </a>
          <a
            onClick={onClick}
            className="text-5xl text-white underline-white leading-[1.5em]"
            href="/klienci-ifil"
          >
            Bezpłatny dostęp
          </a>
        </div>
      </div>
    </div>
  );
}
