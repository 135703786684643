import React, { useState } from "react";
import "intersection-observer";

export default function Faq() {
  const [activeIndex, setActiveIndex] = useState(0);

  const questions = [
    "Jak będzie wyglądała moja oferta?",
    "Kiedy projekt będzie gotowy?",
    "Czy możemy rozbudować aplikację?",
    "Jak wykupić dostęp?",
  ];

  const answers = [
    "Każdy szablon oferty jest indywidualnie projektowany oraz programowany. Dzięki Twój szablon będzie w pełni spójny z identyfikacją wizualną Twojej firmy oraz stroną internetową. W efekcie wyróżnisz się na tle konkurencji w procesie ofertowania oraz dasz się zapamiętać potencjalnemu Klientowi!",
    "Szablon oferty realizowany jest w przeciągu 7 dni od zakupu planu podstawowego bądź spersonalizowanego.",
    "Tak! Każda funkcjonalność jest wyceniana indywidualnie oraz może zostać wdrożona w przypadku pakietu 'Spersonalizowanego'.",
    "Po założeniu bezpłatnego konta a następnie w panelu Klienta kliknij przycisk 'Zamów wyższy plan'. W razie problemów napisz do nas na hello@letsoffer.pl",
  ];

  return (
    <section id="faq" className="p-6 lg:p-24 bg-main flex flex-col">
      <div className="lg:mb-12">
        <h3 className="text-white text-xl uppercase">FAQ</h3>
        <span className="block w-16 h-[2px] bg-white my-2"></span>
        <h2 className="text-[4vh] lg:text-[8vh] leading-none text-white mb-6">
          Najczęściej zadawane pytania
        </h2>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-5 gap-6 lg:gap-12">
        <div className="col-span-2 flex flex-col gap-2 lg:gap-6">
          {questions.map((question, index) => (
            <div
              key={index}
              className={`p-2 border-b border-white text-lg lg:text-2xl cursor-pointer transition duration-300 ease-linear ${
                activeIndex === index
                  ? "bg-white text-[#ea5b0c]"
                  : "text-white hover:bg-white hover:text-[#ea5b0c]"
              }`}
              onClick={() => setActiveIndex(index)}
            >
              <p>{question}</p>
            </div>
          ))}
        </div>
        <div className="col-span-3">
          {answers.map((answer, index) => (
            <div
              key={index}
              className={`transition-opacity duration-300 ${
                activeIndex === index ? "block" : "hidden"
              }`}
            >
              <p className="text-white text-base lg:text-lg">{answer}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
