import { FaCcMastercard, FaCcVisa } from "react-icons/fa";

export default function Footer() {
  return (
    <section className="px-6 lg:px-24 py-6 lg:py-12 bg-add flex flex-col">
      <div className="flex flex-col lg:flex-row items-start justify-between">
        <div className="shrink-0 flex flex-col items-start justify-start">
          <img
            className="h-16"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/lets-offer/logo-white.png"
            alt=""
          />
          <p className="mt-6 text-white opacity-70">
            iFil Group
            <br />
            NIP: PL8943219325
            <br />
            REGON: 526343129
            <br />
            hello@letsoffer.pl
          </p>
        </div>
        <div className="flex flex-col items-end justify-start mt-6 lg:mt-0">
          <div className="w-full flex flex-col lg:flex-row items-start justify-end gap-6 lg:gap-12 mb-6 lg:mb-12">
            <div className="flex flex-col">
              <p className="text-3xl mb-2 text-white">Przydatne linki</p>
              <a
                className="text-white opacity-70 hover:opacity-100 transition duration-300 ease-linear"
                href="/#jak-to-dziala"
              >
                Jak to działa?
              </a>
              <a
                className="text-white opacity-70 hover:opacity-100 transition duration-300 ease-linear"
                href="/#funkcjonalnosci"
              >
                Funkcjonalności
              </a>
              <a
                className="text-white opacity-70 hover:opacity-100 transition duration-300 ease-linear"
                href="/#cennik"
              >
                Cennik
              </a>
            </div>
            <div className="flex flex-col">
              <p className="text-3xl mb-2 text-white">Nasze produkty</p>
              <a
                className="text-white opacity-70 hover:opacity-100 transition duration-300 ease-linear"
                href="https://managly.pl"
              >
                Managly
              </a>
              <a
                className="text-white opacity-70 hover:opacity-100 transition duration-300 ease-linear"
                href="https://contractorflow.pl"
              >
                Contractor Flow
              </a>
              <a
                className="text-white opacity-70 hover:opacity-100 transition duration-300 ease-linear"
                href="https://porownajsolary.pl"
              >
                Porównaj Solary
              </a>
            </div>
            <div className="flex flex-col">
              <p className="text-3xl mb-2 text-white">Dokumenty</p>
              <a
                className="text-white opacity-70 hover:opacity-100 transition duration-300 ease-linear"
                href="/polityka-prywatnosci"
              >
                Polityka prywatności
              </a>
              <a
                className="text-white opacity-70 hover:opacity-100 transition duration-300 ease-linear"
                href="/regulamin-serwisu"
              >
                Regulamin serwisu
              </a>
              <a
                className="text-white opacity-70 hover:opacity-100 transition duration-300 ease-linear"
                href="/obowiazek-informacyjny"
              >
                Obowiązek informacyjny
              </a>
            </div>
          </div>
          <div className="flex flex-row items-center justify-end gap-2">
            <p className="text-white opacity-70">
              Bezpieczne płatności zapewnia
            </p>
            <FaCcVisa className="text-3xl text-white" />
            <FaCcMastercard className="text-3xl text-white" />
          </div>
        </div>
      </div>
      <span className="w-full bg-white block h-[1px] mt-6 lg:mt-12 mb-6 opacity-50"></span>
      <div className="flex flex-row items-center justify-between">
        <a href="https://ifil.pl">
          <img
            className="h-6 opacity-50"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/2024/ifil-pl/logo-white.png"
            alt=""
          />
        </a>
        <a
          className="text-white opacity-50 underline-white hidden lg:block"
          href="https://ifil.pl"
        >
          by iFil Group Filip Kania
        </a>
      </div>
    </section>
  );
}
