import { useState, ChangeEvent, FocusEvent } from "react";
import { FaSearch } from "react-icons/fa";
import axios from "axios";
import API_BASE_URL from "../../config";
import { Offer } from "../../types/Offer";

export default function Search() {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState<Offer[]>([]);
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    if (e.target.value.length > 1) {
      axios
        .get(`${API_BASE_URL}/api/search/offers/?q=${e.target.value}`, {
          headers: {
            Authorization: `Token ${localStorage.getItem("authToken")}`,
          },
        })
        .then((response) => {
          setResults(response.data);
        })
        .catch((error) =>
          console.error("Error fetching search results", error)
        );
    } else {
      setResults([]);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (e: FocusEvent<HTMLDivElement>) => {
    if (!e.currentTarget.contains(e.relatedTarget as Node)) {
      setIsFocused(false);
    }
  };

  return (
    <div
      className="relative w-full p-2 bg-transparent"
      onBlur={handleBlur}
      onFocus={handleFocus}
      tabIndex={-1}
    >
      <input
        type="text"
        value={query}
        onChange={handleChange}
        className="bg-transparent pl-8 pr-2 py-2 outline-none w-full border-b border-gray-400 focus:border-[#ea5b0c] focus:placeholder:opacity-50 placeholder:transition placeholder:duration-300 placeholder:ease-linear transition duration-300 ease-linear"
        placeholder="Znajdź swoją ofertę"
      />
      <FaSearch className="absolute left-2 top-1/2 transform -translate-y-1/2 text-xl color-main pointer-events-none" />
      {isFocused && results.length > 0 && (
        <div className="absolute bg-white shadow-lg mt-2 w-full z-50">
          {results.map((offer) => (
            <a
              href={`/panel/${offer.slug}`}
              key={offer.id}
              className="block p-2 border-b hover:bg-gray-200 cursor-pointer"
            >
              <p>
                {offer.service_name} - {offer.client_name}
              </p>
            </a>
          ))}
        </div>
      )}
    </div>
  );
}
