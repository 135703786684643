import { useState, useEffect } from "react";
import { CgWebsite } from "react-icons/cg";
import { FiPhoneCall } from "react-icons/fi";
import { MdOutlineMail } from "react-icons/md";
import API_BASE_URL from "../../config";
import { IoIosContact } from "react-icons/io";

export default function ContactFormSettings() {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [website, setWebsite] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  useEffect(() => {
    fetchProfileData();
  }, []);

  const fetchProfileData = async () => {
    const token = localStorage.getItem("authToken");

    if (!token) {
      setError("No token found. Please log in.");
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/api/auth/profile/`, {
        method: "GET",
        headers: {
          Authorization: `Token ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setEmail(data.email);
        setPhone(data.phone);
        setWebsite(data.website);
        setError(null);
      } else {
        const result = await response.json();
        setError(result.errors || "Failed to fetch profile data.");
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
      setError("An error occurred while fetching profile data.");
    }
  };

  const handleUpdate = async () => {
    const token = localStorage.getItem("authToken");

    if (!token) {
      setError("No token found. Please log in.");
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/api/auth/profile/update/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify({ email, phone, website }),
      });

      if (response.ok) {
        const result = await response.json();
        setSuccess("Profile updated successfully");
        setError(null);
      } else {
        const result = await response.json();
        if (typeof result.errors === "object") {
          setError(Object.values(result.errors).join(", "));
        } else {
          setError(result.errors);
        }
        setSuccess(null);
      }
    } catch (error) {
      console.error("Error occurred:", error);
      setError("An error occurred. Please try again.");
      setSuccess(null);
    }
  };

  return (
    <div className="flex flex-col p-6 lg:p-12 gap-2 shadow-main items-start justify-start">
      <div className="flex flex-col lg:flex-row items-start lg:items-center justify-start gap-2">
        <IoIosContact className="text-3xl color-main" />
        <h2 className="text-4xl color-main">Dane kontaktowe</h2>
      </div>
      <p className="text-base color-add opacity-80 mb-2">
        Dane kontaktowe wyświetlą się na każdej z Twoich ofert. Zadbaj o ich
        poprawność.
      </p>
      <div className="flex flex-col gap-2 lg:gap-6 w-full items-start justify-start">
        <div className="relative w-full p-2">
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="pl-8 pr-2 py-2 outline-none w-full border-b border-gray-400 focus:border-[#ea5b0c] focus:placeholder:opacity-50 placeholder:transition placeholder:duration-300 placeholder:ease-linear transition duration-300 ease-linear"
            placeholder="Twój adres e-mail do oferty"
          />
          <MdOutlineMail className="absolute left-2 top-1/2 transform -translate-y-1/2 text-xl color-main pointer-events-none" />
        </div>
        <div className="relative w-full p-2">
          <input
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            className="pl-8 pr-2 py-2 outline-none w-full border-b border-gray-400 focus:border-[#ea5b0c] focus:placeholder:opacity-50 placeholder:transition placeholder:duration-300 placeholder:ease-linear transition duration-300 ease-linear"
            placeholder="Twój numer telefonu do oferty"
          />
          <FiPhoneCall className="absolute left-2 top-1/2 transform -translate-y-1/2 text-xl color-main pointer-events-none" />
        </div>
        <div className="relative w-full p-2">
          <input
            type="text"
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
            className="pl-8 pr-2 py-2 outline-none w-full border-b border-gray-400 focus:border-[#ea5b0c] focus:placeholder:opacity-50 placeholder:transition placeholder:duration-300 placeholder:ease-linear transition duration-300 ease-linear"
            placeholder="Adres Twojej strony internetowej do oferty"
          />
          <CgWebsite className="absolute left-2 top-1/2 transform -translate-y-1/2 text-xl color-main pointer-events-none" />
        </div>
        <button onClick={handleUpdate} className="button-main">
          Aktualizuj
        </button>
      </div>
    </div>
  );
}
