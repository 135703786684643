import { useEffect, useRef } from "react";

export default function Hero() {
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (svgRef.current) {
        svgRef.current.classList.add("active");
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (svgRef.current) {
        svgRef.current.classList.add("animate-bounce");
      }
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const video = document.querySelector("video");
    if (video) {
      video.play().catch((error) => {
        console.error("Autoplay failed:", error);
      });
    }
  }, []);

  const videoRef = useRef<HTMLVideoElement | null>(null);
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.defaultMuted = true;
    }
  }, []);

  return (
    <section className="lg:py-28 bg-gray-50">
      <div className="p-6 lg:p-24">
        <h2 className="hidden lg:block bold text-[5vh] lg:text-[12vh] leading-none color-add mb-12">
          Ofertowanie on-line
          <br />
          zwiększające <span className="color-main bold">sprzedaż</span>
        </h2>
        <h2 className="block lg:hidden bold text-[4vh] lg:text-[12vh] leading-none color-add opacity-80 mb-12">
          Ofertowanie
          <br />
          on-line zwiększające <span className="color-main bold">sprzedaż</span>
        </h2>
        <div className="flex flex-col lg:flex-row items-center justify-start gap-6">
          <div>
            <svg
              ref={svgRef}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              width={80}
              className="z-10 hidden lg:block"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  d="M8.34315 14H14M14 14V8.34315M14 14L5.63604 5.63604M3.17216 10.2432C2.60828 13.0904 3.42957 16.1575 5.63604 18.364C9.15076 21.8787 14.8492 21.8787 18.364 18.364C21.8787 14.8492 21.8787 9.15076 18.364 5.63604C16.1575 3.42957 13.0904 2.60828 10.2432 3.17216"
                  stroke="#444444"
                  stroke-width="0.984"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="svg-elem-1"
                ></path>
              </g>
            </svg>
            <div className="block lg:hidden">
              <video
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/lets-offer/instuction-video.mov"
                width="320"
                height="240"
                controls={false}
                className="block shadow-xl"
                muted
                loop
                autoPlay
                ref={videoRef}
                playsInline
              ></video>
            </div>
          </div>
          <div>
            <p className="text-xl lg:text-3xl color-add opacity-80">
              Let's Offer pozwala na tworzenie ofert on-line na przygotowanym
              wcześniej szablonie. Do każdej oferty generuje się link, który
              możesz wysłać prosto do Klienta.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
