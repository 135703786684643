import CaseStudyCarousel from "./carousel";

export default function CaseStudy() {
  return (
    <section
      id="case-study"
      className="lg:pt-36 pb-12 flex flex-col z-50 pt-6 bg-gray-50"
    >
      <div className="flex flex-col lg:flex-row items-center justify-between px-6 lg:px-24 gap-6">
        <div className=" w-full flex flex-col items-start justfiy-center">
          <h3 className="color-main text-xl uppercase">Case study</h3>
          <span className="block w-16 h-[2px] bg-main my-2"></span>
          <h2 className="text-[4vh] lg:text-[8vh] leading-none color-add lg:mb-12">
            Jak wykorzystać Let's Offer?
          </h2>
        </div>
        <div className="w-full flex flex-col items-start justfiy-center">
          <p className="text-[2.5vh] color-add opacity-80 lg:mb-6">
            Oprogramowanie stworzone zostało z myślą o firmach usługowych oraz
            handlowych. Możesz wykorzystać Let's Offer do ofertowania w dowolnej
            branży.
          </p>
        </div>
      </div>
      <div className="lg:px-16">
        <div className="">
          <CaseStudyCarousel />
        </div>
      </div>
    </section>
  );
}
