import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import API_BASE_URL from "../../config";

interface Post {
  id: number;
  title: string;
  slug: string;
  content: string;
  excerpt: string;
  created_at: string;
  updated_at: string;
  category: {
    id: number;
    name: string;
    slug: string;
  };
  image_url: string;
  type: string;
}

const CaseStudyCarousel: React.FC = () => {
  const [posts, setPosts] = useState<Post[]>([]);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/api/blog/casestudies/`)
      .then((response) => setPosts(response.data))
      .catch((error) => console.error("Error fetching case studies:", error));
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {posts.map((post) => (
        <div key={post.id} className="px-6 py-6 lg:py-12">
          <a
            className="overflow-hidden shadow-xl hover:shadow-2xl transition duration-300 hover:-translate-y-2 ease-linear flex flex-col group relative"
            href={`/post/${post.slug}`}
          >
            <div className="absolute bg-main top-12 left-0 z-50 px-6 py-2">
              <p className="text-white">{post.type}</p>
            </div>
            <img
              src={post.image_url ? post.image_url : ""}
              alt={post.title}
              className="scale-[102%]"
            />

            <div className="p-6 bg-white">
              <h4 className="text-3xl color-add group-hover:text-[#ea5b0c] transition duration-300 ease-linear">
                {post.title}
              </h4>
              <p className="my-2 color-add opacity-80">{post.excerpt}</p>
            </div>
          </a>
        </div>
      ))}
    </Slider>
  );
};

export default CaseStudyCarousel;
