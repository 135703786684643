import { useState } from "react";

import HeaderLeft from "../compontents/common/headerLeft";
import HeaderSlide from "../compontents/common/headerSlide";

import Header from "../compontents/panel/header";
import Stats from "../compontents/panel/stats";
import Table from "../compontents/panel/table";
import Subscription from "../compontents/panel/subscription";
import Contact from "../compontents/panel/contact";

export default function Panel() {
  const [showSlide, setShowSlide] = useState(false);

  return (
    <>
      {showSlide && <HeaderSlide onClick={() => setShowSlide(false)} />}
      <div className="flex flex-row items-start justify-start h-screen">
        <HeaderLeft onClick={() => setShowSlide(true)} />
        <div className="w-full h-screen overflow-y-auto bg-gray-50">
          <Header />
          <Stats />
          <Table />
          <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-12 px-6 lg:px-12 pb-12">
            <Subscription />
            <Contact />
          </div>
        </div>
      </div>
    </>
  );
}
