export default function Functionalities() {
  return (
    <section
      id="funkcjonalnosci"
      className="p-6 lg:p-24 bg-gray-50 grid grid-cols-1 lg:grid-cols-2 gap-12"
    >
      <div>
        <h3 className="color-main text-xl uppercase">Funkcjonalności</h3>
        <span className="block w-16 h-[2px] bg-main my-2"></span>
        <h2 className="text-[4vh] lg:text-[8vh] leading-none color-add mb-6">
          Prostota systemu ofertowania
        </h2>
        <p className="text-[2.5vh] color-add opacity-80 mb-12">
          Projektując Let's Offer zadbaliśmy o przejrzysty oraz funkcjonalny
          interfejs graficzny, który pozwoli Ci na szybkie tworzenie ofert dla
          potencjalnych Klientów.
        </p>
        <div className="flex flex-row items-start justify-start gap-6 mb-6">
          <img
            className="w-16 rounded-xl bg-main p-2"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/lets-offer/funkcjonalnosci-lista-n.png"
            alt="Ikona"
          />
          <div className="flex flex-col gap-1">
            <h5 className="color-main text-2xl font-bold">Baza Twoich ofert</h5>
            <p className="text-lg w-full lg:w-3/4 color-add opacity-80">
              Przejrzysta lista wszystkich Twoich ofert z możliwością sortowania
              oraz filtrowania.
            </p>
          </div>
        </div>
        <div className="flex flex-row items-start justify-start gap-6 mb-6">
          <img
            className="w-16 rounded-xl bg-main p-2"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/lets-offer/funkcjonalnosci-status.png"
            alt="Ikona"
          />
          <div className="flex flex-col gap-1">
            <h5 className="color-main text-2xl font-bold">Status odczytania</h5>
            <p className="text-lg w-full lg:w-3/4 color-add opacity-80">
              Dowiedz się, którzy Twoi potencjalni klienci otworzyli już Twoją
              ofertę.
            </p>
          </div>
        </div>
        <div className="flex flex-row items-start justify-start gap-6">
          <img
            className="w-16 rounded-xl bg-main p-2"
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/lets-offer/funkcjonalnosci-szablony.png"
            alt="Ikona"
          />
          <div className="flex flex-col gap-1">
            <h5 className="color-main text-2xl font-bold">Wybór szablonu</h5>
            <p className="text-lg w-full lg:w-3/4 color-add opacity-80">
              Wybierz gotowy szablon oferty bądź zamów interfejs spójny z Twoją
              stroną.
            </p>
          </div>
        </div>
      </div>
      <div className="">
        <img
          className="sticky top-32 drop-shadow-2xl"
          src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/lets-offer/additional-ui.png"
          alt=""
        />
      </div>
    </section>
  );
}
