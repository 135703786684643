import { FaBarsStaggered } from "react-icons/fa6";

interface Props {
  onClick: () => void;
}

export default function HeaderLeft({ onClick }: Props) {
  return (
    <header className="w-[5%] h-full border-r z-[99999999] relative bg-gray-50 hidden lg:flex">
      <div className="h-full flex flex-col items-center justify-between px-4 py-6">
        <div>
          <FaBarsStaggered
            onClick={onClick}
            className="z-50 cursor-pointer color-main text-4xl"
          />
        </div>
        <img
          src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/lets-offer/logo-rotate.png"
          alt=""
        />
      </div>
    </header>
  );
}
