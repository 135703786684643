import { useState, useEffect, ChangeEvent, FormEvent } from "react";
import { FaCalendarAlt, FaCoins, FaPlus, FaRegUser } from "react-icons/fa";
import { FaNoteSticky } from "react-icons/fa6";
import { IoIosCloseCircle, IoMdSettings } from "react-icons/io";
import { MdDriveFileRenameOutline } from "react-icons/md";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Offer, OfferVariant } from "../../types/Offer";
import { Template } from "../../types/Template";
import API_BASE_URL from "../../config";
import { LuLayoutTemplate } from "react-icons/lu";
import { z } from "zod";

export default function OfferForm() {
  const [subscriptionPlan, setSubscriptionPlan] = useState(
    "Bezpłatny okres próbny"
  );
  const [totalOffers, setTotalOffers] = useState(0);
  useEffect(() => {
    const token = localStorage.getItem("authToken");

    if (token) {
      const fetchSubscriptionData = async () => {
        try {
          const response = await axios.get(
            `${API_BASE_URL}/api/auth/profile/`,
            {
              headers: {
                Authorization: `Token ${token}`,
              },
            }
          );
          setSubscriptionPlan(
            response.data.subscription_plan || "Bezpłatny okres próbny"
          );
        } catch (error) {
          console.error("Error fetching subscription data:", error);
        }
      };

      const fetchTotalOffers = async () => {
        try {
          const response = await axios.get(`${API_BASE_URL}/api/offers`, {
            headers: {
              Authorization: `Token ${token}`,
            },
          });
          setTotalOffers(response.data.length);
        } catch (error) {
          console.error("Error fetching total offers:", error);
        }
      };

      const fetchData = async () => {
        await fetchSubscriptionData();
        await fetchTotalOffers();
      };

      fetchData();
    } else {
      console.error("No auth token found");
    }
  }, []);

  const [offer, setOffer] = useState<Offer>({
    id: 0,
    user: {
      id: 0,
      email: "",
      website: "",
      phone: "",
    },
    client_name: "",
    service_name: "",
    creation_date: "01-01-2000",
    expiration_date: "",
    note: "",
    variants: [{ name: "", price: "", duration: "", description: "" }],
    template_id: 1,
  });

  const [templates, setTemplates] = useState<Template[]>([]);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      axios
        .get(`${API_BASE_URL}/api/templates/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((response) => setTemplates(response.data))
        .catch((error) => console.error(error));
    } else {
      console.error("No auth token found");
    }
  }, []);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setOffer((prevOffer) => ({
      ...prevOffer,
      [name]: value,
    }));
  };

  const handleVariantChange = (
    index: number,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    const variants = [...offer.variants];
    variants[index] = { ...variants[index], [name]: value };
    setOffer((prevOffer) => ({
      ...prevOffer,
      variants,
    }));
  };

  const addVariant = () => {
    setOffer((prevOffer) => ({
      ...prevOffer,
      variants: [
        ...prevOffer.variants,
        { name: "", price: "", duration: "", description: "" },
      ],
    }));
  };

  const removeVariant = (index: number) => {
    setOffer((prevOffer) => ({
      ...prevOffer,
      variants: prevOffer.variants.filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formErrors = validateOfferForm(offer);

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      console.log(formErrors);
      return;
    }

    const token = localStorage.getItem("authToken");
    if (!token) {
      console.error("No token found, user might not be authenticated");
      return;
    }

    const offerData = {
      ...offer,
      variants: offer.variants.map((variant) => ({
        ...variant,
      })),
      template_id: offer.template_id,
    };

    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/offers/`,
        offerData,
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Offer created:", response.data);
      navigate("/panel");
    } catch (error: any) {
      if (error.response) {
        console.error("Offer creation error:", error.response.data);
      } else {
        console.error("Offer creation error:", error.message);
      }
    }
  };

  const validateOfferForm = (offer: Offer) => {
    const errors: Record<string, string> = {};

    if (!offer.client_name)
      errors.client_name = "To pole musi zostać uzupełnione";
    if (!offer.service_name)
      errors.service_name = "To pole musi zostać uzupełnione";
    if (!offer.creation_date)
      errors.creation_date = "To pole musi zostać uzupełnione";
    if (!offer.expiration_date)
      errors.expiration_date = "To pole musi zostać uzupełnione";

    offer.variants.forEach((variant, index) => {
      if (!variant.name)
        errors[`variant_name_${index}`] = "To pole musi zostać uzupełnione";
      if (!variant.price)
        errors[`variant_price_${index}`] = "To pole musi zostać uzupełnione";
      if (!variant.duration)
        errors[`variant_duration_${index}`] = "To pole musi zostać uzupełnione";
      if (!variant.description)
        errors[`variant_description_${index}`] =
          "To pole musi zostać uzupełnione";
    });

    return errors;
  };

  if (subscriptionPlan === "Bezpłatny okres próbny" && totalOffers >= 25) {
    return (
      <section className="flex flex-col px-12 pb-12 gap-12">
        <h1 className="text-4xl color-main">
          Twój limit ofert w pakiecie próbnym został przekroczony, zamów wyższy
          plan
        </h1>
      </section>
    );
  } else {
    return (
      <section className="flex flex-col p-6 lg:px-12 lg:pb-12 gap-6 lg:gap-12">
        <h1 className="text-4xl color-main">Utwórz ofertę</h1>
        <form onSubmit={handleSubmit}>
          <div className="shadow-lg p-6 mb-6">
            <h2 className="color-main text-2xl">Dane potencjalnego Klienta</h2>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-2">
              <div className="flex flex-col gap-1">
                <div className="relative w-full p-2">
                  <input
                    type="text"
                    name="client_name"
                    value={offer.client_name}
                    onChange={handleChange}
                    className="pl-8 pr-2 py-2 color-add outline-none w-full border-b border-gray-400 focus:border-[#ea5b0c] focus:placeholder:opacity-50 placeholder:transition placeholder:duration-300 placeholder:ease-linear transition duration-300 ease-linear"
                    placeholder="Imię i nazwisko / Nazwa firmy"
                  />
                  <FaRegUser className="absolute left-2 top-1/2 transform -translate-y-1/2 text-xl color-main pointer-events-none" />
                </div>
                {errors.client_name && (
                  <span className="text-red-600 text-sm">
                    {errors.client_name}
                  </span>
                )}
              </div>
              <div className="flex flex-col gap-1">
                <div className="relative w-full p-2">
                  <input
                    type="text"
                    name="expiration_date"
                    value={offer.expiration_date}
                    onChange={handleChange}
                    className="pl-8 pr-2 py-2 color-add outline-none w-full border-b border-gray-400 focus:border-[#ea5b0c] focus:placeholder:opacity-50 placeholder:transition placeholder:duration-300 placeholder:ease-linear transition duration-300 ease-linear"
                    placeholder="Data ważności oferty"
                  />
                  <FaCalendarAlt className="absolute left-2 top-1/2 transform -translate-y-1/2 text-xl color-main pointer-events-none" />
                </div>
                {errors.expiration_date && (
                  <span className="text-red-600 text-sm">
                    {errors.expiration_date}
                  </span>
                )}
              </div>
              <div className="flex flex-col gap-1">
                <div className="relative w-full p-2">
                  <input
                    type="text"
                    name="service_name"
                    value={offer.service_name}
                    onChange={handleChange}
                    className="pl-8 pr-2 py-2 color-add outline-none w-full border-b border-gray-400 focus:border-[#ea5b0c] focus:placeholder:opacity-50 placeholder:transition placeholder:duration-300 placeholder:ease-linear transition duration-300 ease-linear"
                    placeholder="Nazwa usługi"
                  />
                  <IoMdSettings className="absolute left-2 top-1/2 transform -translate-y-1/2 text-xl color-main pointer-events-none" />
                </div>
                {errors.service_name && (
                  <span className="text-red-600 text-sm">
                    {errors.service_name}
                  </span>
                )}
              </div>
              <div className="flex flex-col gap-1">
                <div className="relative w-full p-2">
                  <input
                    type="text"
                    name="note"
                    value={offer.note}
                    onChange={handleChange}
                    className="pl-8 pr-2 py-2 color-add outline-none w-full border-b border-gray-400 focus:border-[#ea5b0c] focus:placeholder:opacity-50 placeholder:transition placeholder:duration-300 placeholder:ease-linear transition duration-300 ease-linear"
                    placeholder="Dodatkowe informacje do oferty"
                  />
                  <FaNoteSticky className="absolute left-2 top-1/2 transform -translate-y-1/2 text-xl color-main pointer-events-none" />
                </div>
              </div>
              <div className="relative w-full p-2">
                <select
                  name="template_id"
                  value={offer.template_id}
                  onChange={handleChange}
                  className="color-add pl-8 pr-2 py-2 outline-none w-full border-b border-gray-400 focus:border-[#ea5b0c] focus:placeholder:opacity-50 placeholder:transition placeholder:duration-300 placeholder:ease-linear transition duration-300 ease-linear"
                >
                  {templates.map((template) => (
                    <option
                      className="color-add opacity-80"
                      key={template.id}
                      value={template.id}
                    >
                      {template.name}
                    </option>
                  ))}
                </select>
                <LuLayoutTemplate className="absolute left-2 top-1/2 transform -translate-y-1/2 text-xl color-main pointer-events-none" />
              </div>
            </div>
          </div>
          {offer.variants.map((variant, index) => (
            <div key={index} className="shadow-lg p-6 mb-6 relative">
              {offer.variants.length > 1 && (
                <IoIosCloseCircle
                  onClick={() => removeVariant(index)}
                  className="absolute top-2 right-2 text-2xl text-gray-400 cursor-pointer hover:text-red-600 transition duration-300 ease-linear"
                />
              )}
              <h2 className="color-main text-2xl">Wariant nr {index + 1}</h2>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-2">
                <div className="flex flex-col gap-1">
                  <div className="relative w-full p-2">
                    <input
                      type="text"
                      name="name"
                      value={variant.name}
                      onChange={(e) => handleVariantChange(index, e)}
                      className="pl-8 pr-2 py-2 color-add outline-none w-full border-b border-gray-400 focus:border-[#ea5b0c] focus:placeholder:opacity-50 placeholder:transition placeholder:duration-300 placeholder:ease-linear transition duration-300 ease-linear"
                      placeholder="Nazwa wariantu"
                    />
                    <MdDriveFileRenameOutline className="absolute left-2 top-1/2 transform -translate-y-1/2 text-xl color-main pointer-events-none" />
                  </div>
                  {errors[`variant_name_${index}`] && (
                    <span className="text-red-600 text-sm">
                      {errors[`variant_name_${index}`]}
                    </span>
                  )}
                </div>
                <div className="flex flex-col gap-1">
                  <div className="relative w-full p-2">
                    <input
                      type="text"
                      name="price"
                      value={variant.price}
                      onChange={(e) => handleVariantChange(index, e)}
                      className="pl-8 pr-2 py-2 color-add outline-none w-full border-b border-gray-400 focus:border-[#ea5b0c] focus:placeholder:opacity-50 placeholder:transition placeholder:duration-300 placeholder:ease-linear transition duration-300 ease-linear"
                      placeholder="Cena"
                    />
                    <FaCoins className="absolute left-2 top-1/2 transform -translate-y-1/2 text-xl color-main pointer-events-none" />
                  </div>
                  {errors[`variant_price_${index}`] && (
                    <span className="text-red-600 text-sm">
                      {errors[`variant_price_${index}`]}
                    </span>
                  )}
                </div>
                <div className="flex flex-col gap-1">
                  <div className="relative w-full p-2">
                    <input
                      type="text"
                      name="duration"
                      value={variant.duration}
                      onChange={(e) => handleVariantChange(index, e)}
                      className="pl-8 pr-2 py-2 color-add outline-none w-full border-b border-gray-400 focus:border-[#ea5b0c] focus:placeholder:opacity-50 placeholder:transition placeholder:duration-300 placeholder:ease-linear transition duration-300 ease-linear"
                      placeholder="Czas realizacji"
                    />
                    <FaCalendarAlt className="absolute left-2 top-1/2 transform -translate-y-1/2 text-xl color-main pointer-events-none" />
                  </div>
                  {errors[`variant_duration_${index}`] && (
                    <span className="text-red-600 text-sm">
                      {errors[`variant_duration_${index}`]}
                    </span>
                  )}
                </div>
                <div className="flex flex-col gap-1">
                  <div className="relative w-full p-2">
                    <input
                      type="text"
                      name="description"
                      value={variant.description}
                      onChange={(e) => handleVariantChange(index, e)}
                      className="pl-8 pr-2 py-2 color-add outline-none w-full border-b border-gray-400 focus:border-[#ea5b0c] focus:placeholder:opacity-50 placeholder:transition placeholder:duration-300 placeholder:ease-linear transition duration-300 ease-linear"
                      placeholder="Opis wariantu"
                    />
                    <FaNoteSticky className="absolute left-2 top-1/2 transform -translate-y-1/2 text-xl color-main pointer-events-none" />
                  </div>
                  {errors[`variant_description_${index}`] && (
                    <span className="text-red-600 text-sm">
                      {errors[`variant_description_${index}`]}
                    </span>
                  )}
                </div>
              </div>
            </div>
          ))}
          {offer.variants.length < 5 && (
            <button
              type="button"
              onClick={addVariant}
              className="button-main-outlined shrink-0 w-full"
            >
              <FaPlus className="color-main" />
              <span>Dodaj wariant</span>
            </button>
          )}
          <button type="submit" className="button-main mt-6 w-full">
            <FaPlus className="text-white" />
            <span>Utwórz ofertę</span>
          </button>
        </form>
      </section>
    );
  }
}
